import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { CompanyCreateData } from "../../models/company";
import { CompanyLocCreateData } from "../../models/company-loc";

type FormatLocalizationFormGroupData = {
  language: FormControl<string>;
  title: FormControl<string>;
};

@Component({
  selector: "m-admin-add-company-modal",
  templateUrl: "./add-company.view.html",
  styleUrls: ["./add-company.view.scss"],
})
export class AddCompanyView {
  @Output()
  onCompanyAdded = new EventEmitter<CompanyCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form!: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
  ) {}

  ngOnInit() {
    this.form = this.createForm();
  }

  createCompany() {
    const formData = this.form.getRawValue();
    const localizationData: CompanyLocCreateData[] = [];

    for (const lang of Object.keys(formData.localization)) {
      const valueLoc = formData.localization[lang];
      localizationData.push({
        companyId: 0,
        title: valueLoc.title,
        lang,
      });
    }
    formData.localization = localizationData;

    this.onCompanyAdded.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  createForm() {
    const localization: { [key: string]: FormGroup<FormatLocalizationFormGroupData> } = {};

    for (const { value } of this.availableLanguages) {
      localization[value] = this.formBuilder.group({
        language: this.formBuilder.control(value, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        title: this.formBuilder.control("", {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(/[\S]/g)],
        }),
      });
    }

    return this.formBuilder.group({
      localization: this.formBuilder.record(localization),
    });
  }

  protected getLanguages() {
    return this.availableLanguages.map((v) => v.value);
  }
}
