import { Component, EventEmitter, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { CalculatorBookData, CalculatorResult, CreditsPricingData } from "@metranpage/pricing-data";
import { UserBalance } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";

@Component({
  selector: "m-buying-credits",
  templateUrl: "./buying-credits.view.html",
  styleUrls: ["./buying-credits.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class BuyingCreditsView {
  @Input()
  pricingData!: CreditsPricingData;
  @Input()
  userBalance!: UserBalance;
  @Input()
  creditPrice = 0;
  @Input()
  bookPrice = 0;
  @Input()
  customCreditsCount = 200;
  @Input()
  bookData?: CalculatorBookData;
  @Input()
  creditsCalculatorResultData?: CalculatorResult;
  @Input()
  isBookPresent = false;
  @Input()
  authorListSymbolsCount = 40_000;
  @Input()
  isCreditsInfoPanelLoading = false;
  @Input()
  isCreditsCalculatorLoading = false;
  @Input()
  isCalculatorPanelLoading = false;
  @Input()
  isLoadingPromocodePanel = false;
  @Input()
  isCreditsTableLoading = false;
  @Input()
  isPromocodeEnable = false;

  @Output()
  onCalculateClick = new EventEmitter<CalculatorBookData>();
  @Output()
  onBuyCreditsClick = new EventEmitter<number>();
  @Output()
  onPayGoldCreditsClick = new EventEmitter<number>();
  @Output()
  onLayoutBookClick = new EventEmitter<number>();
  @Output()
  onDroppedFileEvent = new EventEmitter<NgxFileDropEntry[]>();

  onCalculate(data: CalculatorBookData) {
    this.onCalculateClick.emit(data);
  }

  onCreditsBuy(value: number) {
    this.onBuyCreditsClick.emit(value);
  }

  onPayGoldCredits(value: number) {
    this.onPayGoldCreditsClick.emit(value);
  }

  onLayoutBook(credits: number) {
    this.onLayoutBookClick.emit(credits);
  }

  dropped(files: NgxFileDropEntry[]) {
    this.onDroppedFileEvent.emit(files);
  }
}
