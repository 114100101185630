import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { BalanceData } from "@metranpage/user-payment-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-low-balance-modal",
  templateUrl: "./low-balance-modal.component.html",
  styleUrls: ["./low-balance-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fade-scale", [
      transition("void => *", [
        style({ opacity: "0", transform: "scale(0)" }),
        animate("300ms ease-out", style({ opacity: "1", transform: "scale(1)" })),
      ]),
      transition("* => void", [
        style({ opacity: "1", transform: "scale(1)" }),
        animate("300ms ease-out", style({ opacity: "0", transform: "scale(0)" })),
      ]),
    ]),
  ],
})
export class LowBalanceModalComponent implements OnChanges {
  // @HostBinding("@fade") animate = true;

  @Input()
  balanceData!: BalanceData;

  @Input()
  isImageGeneration = false;
  @Input()
  imageGenerationMode: "upscale" | "unzoom" | undefined = undefined;

  @Input()
  currency = "₽";

  @Input()
  tariffs!: Tariff[];

  @Input()
  activeSubscription?: ActiveSubscription;

  @Output()
  close = new EventEmitter();

  @Output()
  payWithGoldCredits = new EventEmitter();

  @Output()
  buySubscription = new EventEmitter<Tariff>();

  bookPriceText!: string;
  userCreditsText!: string;
  upgradeRecommendations!: string;

  constructor(private readonly pricingViewService: PricingViewService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.balanceData) {
      this.defineTexts();
    }
  }

  private defineTexts() {
    if (this.balanceData) {
      this.bookPriceText = `${this.pricingViewService.thousandsFormat(
        this.balanceData.price,
      )} ${this.pricingViewService.pluralizeCredits(this.balanceData.price)}`;

      const totalCredits = this.balanceData.userBalance.credits;
      this.userCreditsText = `${this.pricingViewService.thousandsFormat(
        totalCredits,
      )} ${this.pricingViewService.pluralizeCredits(totalCredits)}`;

      this.upgradeRecommendations = $localize`:@@books.preview.payments-modal.header-02:`;
      if (this.isImageGeneration) {
        this.upgradeRecommendations = $localize`:@@books.preview.payments-modal.header-02:`;
      }
    }
  }

  protected hasGoldCredits(): boolean {
    return (
      !this.isImageGeneration &&
      this.balanceData?.userBalance?.goldCredits !== undefined &&
      this.balanceData.userBalance.goldCredits > 0
    );
  }

  protected onCloseClick() {
    this.close.emit();
  }

  protected getImageGenerationText() {
    let text = $localize`:@@image-generation.generation.payments-modal.header-01:`;
    if (this.imageGenerationMode === "unzoom") {
      text = $localize`:@@image-generation.generation.payments-modal.header-02:`;
    }
    if (this.imageGenerationMode === "upscale") {
      text = $localize`:@@image-generation.generation.payments-modal.header-03:`;
    }
    return text;
  }

  @HostListener("document:keydown.escape", ["$event"])
  onEscape(event: KeyboardEvent) {
    this.close.emit();
  }
}
