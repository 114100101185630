import { Component, EventEmitter, Input, Output } from "@angular/core";
import { User } from "@metranpage/user-data";
import { Reward } from "../../models/reward";

@Component({
  selector: "m-account-rewards-view",
  templateUrl: "account-rewards.view.html",
  styleUrls: ["account-rewards.view.scss"],
})
export class AccountRewardsView {
  @Input()
  user?: User;
  @Input()
  rewards: Reward[] = [];

  @Output()
  onScroll = new EventEmitter();

  protected onScrollEvent() {
    this.onScroll.emit();
  }

  protected getTitle(reward: Reward) {
    switch (reward.kind) {
      case "connect-telegram":
        return $localize`:@@user.profile.rewards.subscribe-to-telegram-channel:`;
      default:
        return "";
    }
  }

  protected getIconSrc(reward: Reward) {
    switch (reward.kind) {
      case "connect-telegram":
        return "reward-telegram.svg";
      default:
        return "";
    }
  }
}
