<m-ng-island [paddingSize]="24">
  <div *ngIf="company; else noCompany" class="container">
    <m-button button-style="primary" (onClick)="onEditClick()" i18n="@@admin.common.edit">admin.common.edit</m-button>

    <div class="info">
      <div>
        <span class="field-name" i18n="@@admin.companies.company.title">admin.companies.company.title</span>
        : {{company.title}}
      </div>
      <div>
        <span class="field-name" i18n="@@admin.companies.company.knowledge-base-url">
          admin.companies.company.knowledge-base-url
        </span>
        :
        <a [href]="company.knowledgeBaseUrl" target="_blank">{{company.knowledgeBaseUrl}}</a>
      </div>
    </div>

    <m-modal *ngIf="isEditModalVisible" position="center" (close)="onEditCancel()">
      <m-admin-edit-company
        [company]="company"
        (save)="onEditSave($event)"
        (cancel)="onEditCancel()"
        (error)="onEditError($event)"
      ></m-admin-edit-company>
    </m-modal>
  </div>
  <ng-template #noCompany>No Company loaded</ng-template>
</m-ng-island>
