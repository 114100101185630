import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FeatureToggle } from '../../models/feature-toggle';
import { FeatureToggleAdminService } from '../../services/feature-toggle.service';
import { FeatureToggleAdminStore } from '../../services/feature-toggle.store';

@Component({
  selector: 'm-admin-feature-toggles',
  templateUrl: './feature-toggles.page.html',
  styleUrls: ['./feature-toggles.page.scss'],
})
export class FeatureTogglesPage implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  toggles: FeatureToggle[] = [];

  constructor(
    private readonly featureTogglesService: FeatureToggleAdminService,
    featureTogglesStore: FeatureToggleAdminStore,
  ) {
    featureTogglesStore
      .getFeatureTogglesObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((toggles) => {
        this.toggles = toggles;
      });
  }

  ngOnInit(): void {
    this.featureTogglesService.loadFeatureToggles();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async setToggleValue(title: string, isEnabled: boolean) {
    await this.featureTogglesService.setFeatureToggle(title, isEnabled);
  }
}
