<div class="header" (click)="close()">
  <m-icon src="sidebar-close.svg" class="header-icon" size="s" color="--color-font-main"></m-icon>

  <div class="divider"></div>

  <span class="header-text" i18n="@@books.markup.sidebar.fonts-size-additional-sidebar">
    books.markup.sidebar.fonts-size-additional-sidebar
  </span>
</div>

<div class="sidebar-content">
  <!-- hack for sidebar animation -->
</div>
