import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CalculatorResult } from "@metranpage/pricing-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-credits-calculator-result",
  templateUrl: "./calculator-result.view.html",
  styleUrls: ["./calculator-result.view.scss"],
})
export class CreditsCalculatorResultView {
  @Input()
  result?: CalculatorResult;
  @Input()
  userCredits!: number;
  @Input()
  bookPriceCredits!: number;
  @Input()
  currency = "₽";

  @Output()
  onBuyCreditsClick = new EventEmitter<number>();

  constructor(private pricingService: PricingViewService) {}

  getCalculatedCredits() {
    return this.result?.metranpage?.credits !== undefined;
  }

  protected onBuyCreditsButtonClick(credits: number) {
    this.onBuyCreditsClick.emit(credits);
  }

  protected isPayButtonShow() {
    return this.creditsNotEnough() > 0;
  }

  protected creditsNotEnough() {
    return this.pricingService.creditsNotEnough(this.userCredits, this.bookPriceCredits);
  }

  protected priceFormat(value: number | undefined) {
    if (value === undefined) {
      return;
    }
    return this.pricingService.priceFormat(value, this.currency);
  }
}
