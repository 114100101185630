<div class="container">
  <div class="title">
    <span i18n="@@books.new.project-modal.additional.age-limits">books.new.project-modal.additional.age-limits</span>
    <m-icon
      class="close-icon"
      src="close-cross.svg"
      color="--color-font-main"
      size="xs"
      (click)="close.emit()"
    ></m-icon>
  </div>

  <div class="content">
    <div class="divider"></div>

    <div class="age-group">
      <span class="age-title" (click)="selectAge(0)">0+</span>
      <span class="age-text" i18n="@@books.new.project-modal.additional.age-limits-0">
        books.new.project-modal.additional.age-limits-0
      </span>
    </div>

    <div class="divider"></div>

    <div class="age-group">
      <span class="age-title" (click)="selectAge(6)">6+</span>
      <span class="age-text" i18n="@@books.new.project-modal.additional.age-limits-6">
        books.new.project-modal.additional.age-limits-6
      </span>
    </div>

    <div class="divider"></div>

    <div class="age-group">
      <span class="age-title" (click)="selectAge(12)">12+</span>
      <span class="age-text" i18n="@@books.new.project-modal.additional.age-limits-12">
        books.new.project-modal.additional.age-limits-12
      </span>
    </div>

    <div class="divider"></div>

    <div class="age-group">
      <span class="age-title" (click)="selectAge(16)">16+</span>
      <span class="age-text" i18n="@@books.new.project-modal.additional.age-limits-16">
        books.new.project-modal.additional.age-limits-16
      </span>
    </div>

    <div class="divider"></div>

    <div class="age-group">
      <span class="age-title" (click)="selectAge(18)">18+</span>
      <span class="age-text" i18n="@@books.new.project-modal.additional.age-limits-18">
        books.new.project-modal.additional.age-limits-18
      </span>
    </div>
  </div>
</div>
