import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

interface PageItem {
  text: string;
  page: number;
}

@Component({
  selector: 'm-paginator',
  templateUrl: './paginator.view.html',
  styleUrls: ['./paginator.view.scss'],
})
export class Paginator implements AfterViewInit, OnChanges {
  @Input('pageCount')
  pageCount!: number;

  @Output()
  onPageClicked: EventEmitter<number> = new EventEmitter<number>();

  pages: PageItem[] = [];
  currentPage = 1;

  nearPagesCount = 2;

  ngAfterViewInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.pageCount = changes.pageCount.currentValue;

    this.setupPages();
  }

  onPageClick(page: number) {
    if (page === -1) {
      return;
    }
    this.currentPage = page;
    this.setupPages();
    this.onPageClicked.emit(page - 1);
  }

  /**
   * Set page from outside of component. convert zero begining number to 1
   */
  setCurrentPage(newPage: number) {
    this.currentPage = newPage + 1;
    this.setupPages();
  }

  private setupPages() {
    this.pages = [];

    let nearCurrentPages: number[] = [];
    for (let i = 1; i < this.nearPagesCount + 1; i++) {
      nearCurrentPages.push(this.currentPage + i);
      nearCurrentPages.push(this.currentPage - i);
    }
    nearCurrentPages.push(this.currentPage);
    nearCurrentPages = nearCurrentPages.sort((a, b) => a - b);
    nearCurrentPages = nearCurrentPages.filter((page) => page > 0 && page < this.pageCount);

    // if first near current page is too far ahead, add first page and ...
    if (nearCurrentPages[0] > this.nearPagesCount / 2) {
      this.pages.push({
        page: 1,
        text: '1',
      });
    }

    if (nearCurrentPages[0] > this.nearPagesCount) {
      this.pages.push({
        page: -1,
        text: '...',
      });
    }

    for (let i = 0; i < nearCurrentPages.length; i++) {
      this.pages.push({
        page: nearCurrentPages[i],
        text: `${nearCurrentPages[i]}`,
      });
    }

    if (nearCurrentPages[nearCurrentPages.length - 1] < this.pageCount - this.nearPagesCount / 2) {
      this.pages.push({
        page: -1,
        text: '...',
      });
    }
    if (nearCurrentPages[nearCurrentPages.length - 1] < this.pageCount) {
      this.pages.push({
        page: this.pageCount,
        text: `${this.pageCount}`,
      });
    }
  }
}
