import { Component, Input } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { CreditsPricingData } from "@metranpage/pricing-data";
import { UserBalance } from "@metranpage/user-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-info-panel",
  templateUrl: "./info-panel.view.html",
  styleUrls: ["./info-panel.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class InfoPanelView {
  @Input()
  pricingData!: CreditsPricingData;
  @Input()
  userBalance!: UserBalance;
  @Input()
  isLoading = false;
  @Input()
  currency = "₽";

  isCreditsInfoModalVisible = false;

  constructor(private readonly pricingService: PricingViewService) {}

  protected getCoeffImages() {
    return this.pricingData.coeffs.image;
  }

  protected getCoeffTables() {
    return this.pricingData.coeffs.table;
  }

  protected showCreditsInfoModal() {
    this.isCreditsInfoModalVisible = true;
  }

  protected closeCreditsInfoModal() {
    this.isCreditsInfoModalVisible = false;
  }

  protected pluralizeCredits(value: number) {
    return this.pricingService.pluralizeCredits(value);
  }

  protected pluralizeGoldCredits(value: number) {
    return this.pricingService.pluralizeGoldCredits(value);
  }

  protected pluralizeBooks() {
    return this.pricingService.pluralizeBooks(this.userBalance.goldCredits);
  }

  protected priceFormat() {
    return this.pricingService.priceFormat(this.pricingData.creditPrice, this.currency);
  }

  protected coeffFormat(value: number) {
    return `= ${this.pricingService.thousandsFormat(value, 10000)} ${this.pricingService.pluralizeSymbols(value)}`;
  }
}
