import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyTelegramSettings } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyTelegramSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getTelegramSettings() {
    return this.get<CompanyTelegramSettings>("admin/telegram-settings");
  }

  async updateTelegramSettings(data: CompanyTelegramSettings) {
    return this.post<CompanyTelegramSettings>("admin/telegram-settings", data);
  }
}
