import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { filterUndefined } from "../filterundefined";
import { Reward } from "../models/reward";
import { NotificationsPopUpService } from "./notifications-pop-up.service";
import { RealtimeService } from "./realtime.service";
import { RewardApi } from "./reward.api";
import { RewardStore } from "./reward.store";

@Injectable({
  providedIn: "root",
})
export class RewardService {
  protected soundFile?: HTMLAudioElement;

  constructor(
    private readonly rewardStore: RewardStore,
    private readonly notificationService: NotificationsPopUpService,
    private readonly rewardApi: RewardApi,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<Reward>("reward-update")
      .pipe(filterUndefined())
      .subscribe((rewardUpdate: Reward) => {
        if (rewardUpdate) {
          this.rewardStore.updateReward(rewardUpdate);

          this.playRewardSound();
        }
      });

    this.loadSound();
  }

  async getUnreadRewardsCount() {
    const unreadRewardsCount = await this.rewardApi.getUnreadRewardsCount();
    this.rewardStore.setUnreadRewardsCount(unreadRewardsCount);
  }

  async loadRewardsPaginated(page: number) {
    const notificationsData = await this.rewardApi.loadRewardPaginated(page);
    this.rewardStore.addRewardsToEnd(notificationsData.items);
    this.rewardStore.setRewardsPageCount(notificationsData.pageCount);
  }

  async markRewardsAsRead(): Promise<GeneralResultStatus> {
    try {
      const result = await this.rewardApi.markRewardsAsRead();
      // this.markStoreRewardsAsRead();
      return "success";
    } catch (error: unknown) {
      return "error";
    }
  }

  markStoreRewardsAsRead() {
    const notifications = this.rewardStore.getRewards();
    for (const n of notifications) {
      n.isShown = true;
    }
    this.rewardStore.setRewards(notifications);
    this.rewardStore.setUnreadRewardsCount(0);
  }

  loadSound() {
    this.soundFile = new Audio();
    this.soundFile.src = "./assets/sounds/reward.mp3";
    this.soundFile.load();
  }

  playRewardSound() {
    if (!this.soundFile) {
      return;
    }
    this.soundFile.play();
  }
}
