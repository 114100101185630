import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { User } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { RewardService } from "../../services/reward.service";
import { RewardStore } from "../../services/reward.store";

@Component({
  selector: "m-account-rewards-icon-view",
  templateUrl: "account-rewards-icon.view.html",
  styleUrls: ["account-rewards-icon.view.scss"],
})
export class AccountRewardsIconView {
  @Input()
  user?: User;

  protected unreadRewardsCount = 0;

  sub: Subscription = new Subscription();

  constructor(
    private readonly rewardService: RewardService,
    private readonly rewardStore: RewardStore,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.rewardService.getUnreadRewardsCount();

    this.sub.add(
      this.rewardStore.getUnreadRewardsCountObservable().subscribe((count) => {
        this.unreadRewardsCount = count;
        this.cdr.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
