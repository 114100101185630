import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyTelegramSettings } from "../../models/company";
import { AdminCompanyTelegramSettingsService } from "../../services/telegram-settings/companies-telegram-settings.service";
import { AdminCompanyTelegramSettingsStore } from "../../services/telegram-settings/companies-telegram-settings.store";

@Component({
  selector: "m-admin-telegram-settings-page",
  templateUrl: "./telegram-settings.page.html",
  styleUrls: ["./telegram-settings.page.scss"],
})
export class AdminCompanyTelegramSettingsPage {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyTelegramSettings | undefined> | undefined;

  readonly form: FormGroup;

  constructor(
    private readonly service: AdminCompanyTelegramSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyTelegramSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      canConnect: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
      channelId: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
      channelUrl: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
      botUrl: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.loadTelegramSettings();
    this.settings$ = this.store.getSettingsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        canConnect: settings?.canConnect || false,
        channelId: settings?.channelId || "",
        channelUrl: settings?.channelUrl || "",
        botUrl: settings?.botUrl || "",
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateTelegramSettings(this.form.value as CompanyTelegramSettings);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  isDisabled() {
    return !this.form.valid;
  }
}
