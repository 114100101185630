<m-ng-island [paddingSize]="16">
  <form *ngIf="!!format && form; else noTemplate" [formGroup]="form">
    <div formGroupName="localization">
      <span i18n="@@admin.format.add-format.title">admin.format.add-format.title</span>

      <div class="localizations" *ngFor="let language of getLanguages()" [formGroupName]="language">
        <p class="section-title">{{ language.toUpperCase() }}</p>

        <div class="control">
          <m-ng-input formControlName="title" />
        </div>
      </div>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.value">admin.format.add-format.value</span>
      <m-ng-input formControlName="name" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.type">admin.format.add-format.type</span>
      <m-select formControlName="type" [options]="getFormatTypeOptions()" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.width">admin.format.add-format.width</span>
      <m-ng-input-number formControlName="width" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.height">admin.format.add-format.height</span>
      <m-ng-input-number formControlName="height" />
    </div>

    <div class="divider"></div>
    <div class="end-spacer"></div>

    <div class="bottom-bar">
      <m-switch i18n="@@admin.templates.template.available-to-user" formControlName="isAvailableToUser">
        admin.templates.template.available-to-user
      </m-switch>

      <m-switch
        *ngIf="canCreatePublicFormats"
        i18n="@@admin.templates.template.available-to-companies"
        formControlName="isVisibleToOtherCompanies"
      >
        admin.templates.template.available-to-companies
      </m-switch>

      <m-button button-style="primary" (onClick)="saveFormat()" i18n="@@admin.common.save">admin.common.save</m-button>
    </div>
  </form>
  <ng-template #noTemplate>No template loaded</ng-template>
</m-ng-island>
