import { Component, EventEmitter, Input, Output } from '@angular/core';

export type TemplateTagType = 'editable' | 'non-editable' | 'blocked';
export type TemplateTagSettings = {
  type: TemplateTagType;
  info?: string;
};

@Component({
  selector: 'm-template-preview-tag',
  templateUrl: './template-preview-tag.view.html',
  styleUrls: ['./template-preview-tag.view.scss'],
})
export class TemplatePreviewTag {
  @Input()
  settings?: TemplateTagSettings;

  protected getTagType() {
    return this.settings?.type;
  }

  protected getTagIconSrc() {
    if (this.settings?.type === 'blocked') {
      return 'blocked.svg';
    }
    return 'info-2.svg';
  }

  protected getTagText() {
    if (this.settings?.type === 'blocked') {
      return $localize`:@@books.template.select-template.tag.blocked:`;
    }
    if (this.settings?.type === 'non-editable') {
      return $localize`:@@books.template.select-template.tag.non-editable:`;
    }
    return $localize`:@@books.template.select-template.tag.editable:`;
  }
}
