import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { CalculatorBookData, CalculatorResult } from "@metranpage/pricing-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-credits-calculator",
  templateUrl: "./calculator.view.html",
  styleUrls: ["./calculator.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CreditsCalculatorView {
  @Input()
  bookData?: CalculatorBookData;
  @Input()
  result?: CalculatorResult;
  @Input()
  bookPriceCredits = 0;
  @Input()
  userCredits = 0;
  @Input()
  isLoading = false;
  @Input()
  currency = "₽";

  @Output()
  onCalculateClick = new EventEmitter<CalculatorBookData>();
  @Output()
  onBuyCreditsClick = new EventEmitter<number>();

  bookDataDefault = {
    textCount: 40,
    imagesCount: 25,
    tablesCount: 3,
  };

  protected form = new FormGroup({
    textCount: new FormControl(40, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)],
    }),
    imagesCount: new FormControl(25, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)],
    }),
    tablesCount: new FormControl(3, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)],
    }),
  });

  constructor(private pricingService: PricingViewService) {}

  ngOnInit(): void {
    if (this.bookData) {
      this.form.controls.textCount.setValue(this.bookData?.textCount);
      this.form.controls.imagesCount.setValue(this.bookData?.imagesCount);
      this.form.controls.tablesCount.setValue(this.bookData?.tablesCount);
    } else {
      this.form.controls.textCount.setValue(this.bookDataDefault.textCount);
      this.form.controls.imagesCount.setValue(this.bookDataDefault.imagesCount);
      this.form.controls.tablesCount.setValue(this.bookDataDefault.tablesCount);
    }
  }

  protected isCalculateButtonDisabled() {
    return !this.form.valid;
  }

  protected onCalculateButtonClick() {
    const textCount = this.form.value.textCount!;
    const imagesCount = this.form.value.imagesCount!;
    const tablesCount = this.form.value.tablesCount!;
    this.onCalculateClick.emit({
      textCount: textCount,
      imagesCount: imagesCount,
      tablesCount: tablesCount,
    });
  }

  protected onBuyCreditsButtonClick(credits: number) {
    this.onBuyCreditsClick.emit(credits);
  }

  protected getCalculatedCredits() {
    return this.result?.metranpage?.credits;
  }

  protected isCalculateButtonVisible() {
    return this.result?.metranpage?.credits === undefined;
  }

  protected isRecalculateButtonVisible() {
    return this.result?.metranpage?.credits !== undefined && this.result?.metranpage?.credits >= 0;
  }

  protected priceFormat(value: number | undefined) {
    if (!value) {
      return;
    }
    return this.pricingService.priceFormat(value, this.currency);
  }
}
