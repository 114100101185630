<m-ng-island [paddingSize]="24">
  <div class="new-font">
    <m-button button-style="primary" (onClick)="onShowAddFontModal()" i18n="@@admin.fonts.add-font">
      admin.fonts.add-font
    </m-button>
  </div>

  <form [formGroup]="searchForm">
    <table>
      <thead>
        <th>ID</th>
        <th>
          Name
          <m-ng-input formControlName="name" size="32" />
        </th>
        <th>Family Name</th>
        <th>Subfamily Name</th>
        <th>License</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let font of fonts; trackBy: trackByFontId">
          <td>{{font.id}}</td>
          <td>{{font.name}}</td>
          <td>{{font.familyName}}</td>
          <td>{{font.subfamilyName}}</td>
          <td>
            <div>
              <span>Ru:</span>
              {{font.licenseRu}}
            </div>
            <br />
            <div>
              <span>En:</span>
              {{font.licenseEn}}
            </div>
          </td>

          <td>
            <div class="controls">
              <m-icon
                class="settings-icon"
                src="gear.svg"
                size="s"
                color="--color-font-main"
                (click)="showFontDetails(font)"
              />
              <m-icon
                class="delete-icon"
                src="block-delete.svg"
                size="s"
                color="--color-error"
                (click)="showFontDeleteModal(font)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <m-admin-pagination
    [pageCount]="pageCount"
    [currentPage]="page"
    (onPageChanged)="onPageChanged($event)"
  ></m-admin-pagination>

  <m-admin-add-font-modal
    *ngIf="isAddFontModalVisible"
    (onFontAdded)="onFontAdded($event)"
    (onCancelAddition)="onCloseAddFontModal()"
  ></m-admin-add-font-modal>

  <m-modal *ngIf="isDeleteFontModalVisible" position="center" (close)="onCloseDeleteFontModal()">
    <div class="delete-font-modal">
      <span>
        <span i18n="@@admin.fonts.font.delete-font-confirmation">admin.fonts.font.delete-font-confirmation</span>

        <span *ngIf="fontForDelete" class="font-name">
          «
          <span class="font-name-accent">{{fontForDelete.name}}</span>
          »
        </span>
        ?
      </span>

      <div class="buttons">
        <m-button
          button-style="secondary"
          size="full-width"
          (onClick)="onCloseDeleteFontModal()"
          i18n="@@admin.common.cancel"
        >
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" (onClick)="onDeleteFont()" i18n="@@admin.common.delete">
          admin.common.delete
        </m-button>
      </div>
    </div>
  </m-modal>
</m-ng-island>
