import { AfterContentInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService, LoadingState, NotificationService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-verification-page",
  templateUrl: "./account-verification.page.html",
  styleUrls: ["./account-verification.page.scss"],
})
export class AccountVerificationPage implements AfterContentInit {
  loadingState$: Observable<LoadingState>;

  isEmailSent = false;
  timeoutTimeLeft = 0;

  constructor(
    private readonly userService: UserService,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {
    this.loadingState$ = this.loadingService.loadingState$;
  }

  ngAfterContentInit(): void {
    const qp = this.route.snapshot.queryParams;
    if (qp && "from" in qp) {
      if (qp["from"] !== "registration") {
        this.onSendVerificationEmailClick();
      }
    }
  }

  async onSendVerificationEmailClick() {
    this.notificationsService.closeAll();

    this.loadingService.startLoading();
    const result = await this.userService.sendVerificationEmail();
    if (result === "success") {
      this.isEmailSent = true;
      this.timeoutTimeLeft = 10;

      const timer = setInterval(() => {
        this.timeoutTimeLeft--;
        if (this.timeoutTimeLeft <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }

    this.loadingService.stopLoading();
  }

  onBackClick() {
    const qp = this.route.snapshot.queryParams;
    if (qp && "from" in qp) {
      if (qp["from"] === "account") {
        this.router.navigate(["/account/contacts"]);
      } else if (qp["from"] === "notifications") {
        this.router.navigate(["/account/notifications"]);
      } else if (qp["from"] === "registration") {
        this.notificationService.notifyOnEmailVerification();
        this.router.navigate(["/"]);
      } else if (qp["from"] === "preview") {
        this.router.navigate([`/books/${qp["id"]}/preview`]);
      } else if (qp["from"] === "image-generation") {
        this.router.navigate(["/image-generation"]);
      } else {
        this.router.navigate(["/books"]);
      }
    }
  }
}
