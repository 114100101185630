<div class="container">
  <div class="content">
    <div *ngFor="let itemCreditsCount of itemsCreditsCounts" class="row">
      <div class="column-credits">
        <span class="text-normal">
          <span>{{ itemCreditsCount }}</span>
          <span>{{ pluralizeCredits(itemCreditsCount) }}</span>
        </span>
        <span class="text-small">
          <span>{{ symbolsCount(itemCreditsCount) }}</span>
          <span>{{ pluralizeSymbols(symbolsCountNumber(itemCreditsCount)) }}</span>
        </span>
      </div>

      <div class="column-price">
        <div class="row-price">
          <span *ngIf="isProfitAviable(itemCreditsCount)" class="text-strikethrough">
            {{ fullPrice(itemCreditsCount) }}
          </span>
          <span class="text-large">{{ profitPrice(itemCreditsCount) }}</span>
        </div>
      </div>

      <div class="column-profit">
        <span *ngIf="isProfitAviable(itemCreditsCount)" class="text-accent">{{ profit(itemCreditsCount) }}</span>
      </div>

      <div class="column-buy">
        <m-button
          class="button-buy"
          button-style="primary"
          (onClick)="onBuyCreditsButtonClick(itemCreditsCount)"
          [is-disabled]="false"
          i18n="@@pricing.credit-table.pay"
        >
          pricing.credit-table.pay
        </m-button>
      </div>
    </div>

    <div class="row">
      <form [formGroup]="form">
        <div class="column-credits">
          <span class="text-normal" i18n="@@pricing.credit-table.any-credits-count">
            pricing.credit-table.any-credits-count
          </span>
          <m-ng-input-number
            type="number"
            name="creditsCount"
            formControlName="creditsCount"
          />
          <span *ngIf="!isCreditsInputNotCorrect()" class="text-small">
            <span>{{ symbolsCount(creditsCount) }}</span>
            <span>{{ pluralizeSymbols(symbolsCountNumber(creditsCount)) }}</span>
          </span>
        </div>

        <div class="column-price">
          <div *ngIf="!isCreditsInputNotCorrect()" class="row-price">
            <span *ngIf="isProfitAviable(creditsCount)" class="text-strikethrough">{{ fullPrice(creditsCount) }}</span>
            <span class="text-large">{{ profitPrice(creditsCount) }}</span>
          </div>
          <m-info-block type="error" [labels]="getInfoLabels()" />
        </div>

        <div class="column-profit">
          <span *ngIf="!isCreditsInputNotCorrect() && isProfitAviable(creditsCount)" class="text-accent">
            {{ profit(creditsCount) }}
          </span>
        </div>

        <div class="column-buy">
          <m-button
            button-style="primary"
            (onClick)="onBuyCreditsButtonClick(creditsCount)"
            [is-disabled]="isCreditsInputNotCorrect()"
            i18n="@@pricing.credit-table.pay"
          >
            pricing.credit-table.pay
          </m-button>
        </div>
      </form>
    </div>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</div>
