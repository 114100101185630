<div class="container">
  <div class="header">
    <span i18n="@@pricing.info-panel.header">pricing.info-panel.header</span>
    <div class="balance-wrapper">
      <div class="balance">
        <m-icon src="token.svg" size="s" color="--color-font-main" />
        <span>{{ userBalance.credits }} {{ pluralizeCredits(userBalance.credits) }}</span>
      </div>
      <span *ngIf="userBalance.goldCredits" i18n="@@pricing.info-panel.and">pricing.info-panel.and</span>
      <div class="balance" *ngIf="userBalance.goldCredits">
        <m-icon src="token-gold.svg" size="s" color="--accent" />
        <span>{{ userBalance.goldCredits }} {{ pluralizeGoldCredits(userBalance.goldCredits) }}</span>
      </div>
    </div>
  </div>

  <span class="credits-info" (click)="showCreditsInfoModal()" i18n="@@pricing.info-panel.more-info">
    pricing.info-panel.more-info
  </span>

  <m-credits-info-modal
    *ngIf="isCreditsInfoModalVisible"
    [price]="priceFormat()"
    [imageCoeff]="coeffFormat(getCoeffImages())"
    [tableCoeff]="coeffFormat(getCoeffTables())"
    (close)="closeCreditsInfoModal()"
    class="credits-info-modal"
  ></m-credits-info-modal>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</div>
