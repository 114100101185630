import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Promocode, PromocodeWithAnalitics } from "../../models/promocode";

export type State = {
  promocodes: PromocodeWithAnalitics[];
  promocodesPageCount: number;
  activePromocode: Promocode | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminPromocodesStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      promocodes: [],
      promocodesPageCount: 1,
      activePromocode: undefined,
    };
  }

  getPromocodesObservable() {
    return this.getFieldObservable("promocodes");
  }

  setPromocodes(promocodes: PromocodeWithAnalitics[]) {
    this.update((state) => ({ ...state, promocodes }));
  }

  getPromocodesPageCountObservable() {
    return this.getFieldObservable("promocodesPageCount");
  }

  setPromocodesPageCount(count: number) {
    this.update((state) => ({ ...state, promocodesPageCount: count }));
  }

  setActivePromocode(promocode?: Promocode) {
    this.update((state) => ({ ...state, activePromocode: promocode }));
  }

  getActivePromocodeObservable() {
    return this.getFieldObservable("activePromocode");
  }
}
