<div class="select-wrapper">
  <div class="input-container" [class.error]="showErrorText" [ngClass]="getCssClassList()" (click)="onFieldClick()">
    <svg-icon *ngIf="iconLeft" [src]="getLeftIconAsset()!" class="icon icon-left"></svg-icon>

    <m-icon
      class="icon-info"
      src="info.svg"
      color="--color-font-input-prefix"
      size="s"
      (click)="onShowAgeRestrictionModalClick()"
    ></m-icon>

    <span class="arrow-divider" *ngIf="isArrowDividerShow()"></span>

    <span class="value">
      {{ value }}
    </span>

    <m-icon
      class="icon-arrow"
      [ngClass]="getArrowIconClassList()"
      src="dropdown-arrow-down.svg"
      color="--color-font-input"
      size="xs"
    />
  </div>
  <span class="error-label" *ngIf="showErrorText">{{ errorSelectedText }}</span>

  <div class="select-options-dropdown" *ngIf="isMenuOpened">
    <div
      class="select-option"
      *ngFor="let option of options; trackBy: trackByFn"
      (click)="onOptionClick(option.id)"
      [attr.data-value]="option.value"
    >
      {{ option.value }}
    </div>
  </div>
</div>
