import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-credits-info-modal',
  templateUrl: './credits-info-modal.view.html',
  styleUrls: ['./credits-info-modal.view.scss'],
})
export class CreditsInfoModal {
  @Input()
  price!: string;
  @Input()
  imageCoeff!: string;
  @Input()
  tableCoeff!: string;

  @Output()
  close = new EventEmitter<void>();
}
