import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateTagSettings } from './template-preview-tag/template-preview-tag.view';

@Component({
  selector: 'm-template-preview-card',
  templateUrl: './template-preview-card.view.html',
  styleUrls: ['./template-preview-card.view.scss'],
})
export class TemplatePreviewCard {
  @Input()
  preview!: string;
  @Input()
  title!: string;
  @Input()
  description!: string;
  @Input()
  isDetailsAvailable!: boolean;
  @Input()
  tagSettings?: TemplateTagSettings;

  @Output()
  onSelect = new EventEmitter<void>();
  @Output()
  onPreview = new EventEmitter<void>();
}
