import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Dimensions } from "@metranpage/book-data";
import { timer } from "rxjs";

@Component({
  selector: "m-preview-text",
  templateUrl: "./preview-text.component.html",
  styleUrls: ["./preview-text.component.scss"],
})
export class PreviewTextComponent implements OnInit {
  @Input("preview-size")
  previewSize: Dimensions = { width: 0, height: 0 };
  @Input("font-size")
  fontSize = 12;
  @Input("columns-count")
  columnsCount = 1;
  @Input()
  gutter = 5;
  @Input()
  color = "rgba(113, 116, 170, 32%)";

  @ViewChild("canvas")
  protected canvas!: ElementRef;

  protected ctxPreview!: CanvasRenderingContext2D;

  constructor(
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private readonly changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    timer(100).subscribe(() => {
      this.drawStylePreview();
    });

    // timer(100).subscribe(() => {
    //   this.drawStylePreview();
    // });

    // this.getSize().pipe(
    //   repeat({ delay: 100 }),
    //   filter(data => this.checkLimit()),
    //   take(1)
    // ).subscribe(result => console.log(result));
  }

  // ngOnChanges() {
  //   this.drawStylePreview();
  // }

  private drawStylePreview() {
    if (!this.ctxPreview) {
      this.ctxPreview = this.canvas?.nativeElement.getContext("2d", { willReadFrequently: true });
    }
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;

    this.ctxPreview.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    const width = this.elementRef.nativeElement.offsetStartidth;
    const height = this.elementRef.nativeElement.offsetHeight;

    // canvasElement.width = width;
    // canvasElement.height = height;

    let margin = (this.fontSize * 2) / 3;
    const count = Math.round(height / (this.fontSize + margin));
    margin = (height - count * this.fontSize) / (count - 1);

    this.ctxPreview.beginPath();

    for (let i = 0; i < count; i++) {
      let offsetStart = 0;
      let offsetEnd = 0;
      if (i === 0 || i === Math.ceil(count / 2)) {
        offsetStart = ((width / this.columnsCount - this.gutter * (this.columnsCount - 1)) * 1) / 3;
      }
      if (i === count - 1) {
        offsetEnd = ((width / this.columnsCount - this.gutter * (this.columnsCount - 1)) * 1) / 3;
      }
      const blockWidth = (width - this.gutter * (this.columnsCount - 1)) / this.columnsCount - offsetStart - offsetEnd;
      const blockHeight = this.fontSize;

      for (let j = 0; j < this.columnsCount; j++) {
        this.ctxPreview.rect(
          offsetStart + j * offsetStart + j * offsetEnd + j * (blockWidth + this.gutter),
          i * (this.fontSize + margin),
          blockWidth,
          blockHeight,
        );
      }
    }

    this.ctxPreview.fillStyle = this.color;
    this.ctxPreview.fill();
  }
}
