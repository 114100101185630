import { Inject, Injectable } from "@angular/core";
import { TokenStorageService } from "@metranpage/auth-interfaces";
import { CompanyService } from "@metranpage/company";
import { ApiErrorHandlerService, COMPANY_UUID, Errors, RealtimeService } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import { ThemeService } from "@metranpage/theme";
import { UserStorage, UserStore } from "@metranpage/user-data";
import { LoginResponseDto } from "../models/auth.dto";
import { AuthApi } from "./auth.api";
import { OidcService } from "./oidc.service";

export type LoginResult =
  | "success"
  | "different-login-method"
  | "wrong-credentials"
  | "network-error"
  | "social-login-error"
  | "one-time-token-error";
export type RegistrationResult =
  | "success"
  | "different-login-method"
  | "email-not-unique"
  | "network-error"
  | "social-login-error";
export type RestorePasswordResult =
  | "success"
  | "email-not-found"
  | "cant-restore-pass-for-social-login"
  | "restore-token-not-found"
  | "network-error";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private readonly api: AuthApi,
    private readonly apiErrorHandler: ApiErrorHandlerService,
    private readonly oidcService: OidcService,
    private readonly i18nService: I18nService,
    private readonly themeService: ThemeService,
    @Inject("TokenStorageService")
    private readonly tokenStorage: TokenStorageService,
    private readonly userStorage: UserStorage,
    private readonly userStore: UserStore,
    private readonly realtimeService: RealtimeService,
    private readonly companyService: CompanyService,
    @Inject(COMPANY_UUID) private companyUuid: string,
  ) {}

  async login(email: string, password: string): Promise<LoginResult> {
    try {
      const response = await this.api.login({ email, password, companyUuid: this.companyUuid });
      this.proceedLoginWithUserdata(response);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.WrongCredentials) {
        return "wrong-credentials";
      } else if (error === Errors.DifferentLoginMethod) {
        return "different-login-method";
      }

      return "network-error";
    }
  }

  async register(
    email: string,
    firstName: string,
    password: string,
    role: number,
    promoAgreed: boolean,
    referalToken: string,
  ): Promise<RegistrationResult> {
    try {
      const response = await this.api.register({
        firstName,
        email,
        password,
        promoAgreed,
        role,
        darkTheme: this.themeService.getBrowserTheme() === "dark",
        language: this.i18nService.getLocale(),
        companyUuid: this.companyUuid,
        referalToken,
      });
      this.proceedLoginWithUserdata(response);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.DifferentLoginMethod) {
        return "different-login-method";
      }
      if (error === Errors.EmailMustBeUnique) {
        return "email-not-unique";
      }
      return "network-error";
    }
  }

  logout(): void {
    this.realtimeService.logout();
    this.tokenStorage.setToken(undefined);
    this.userStorage.clear();
    this.userStore.setUser(undefined);
    this.userStore.setActiveSubscription(undefined);
  }

  loginGoogle() {
    this.oidcService.loginGoogle();
  }
  async processGoogleResponse(): Promise<LoginResult> {
    try {
      const googleUser: any = await this.oidcService.processGoogleResponse();
      const user = await this.api.loginGoogle({
        email: googleUser.email,
        socialId: googleUser.sub,
        firstName: googleUser.given_name,
        lastName: googleUser.family_name,
        darkTheme: this.themeService.getTheme() === "dark",
        language: this.i18nService.getLocale(),
        companyUuid: this.companyUuid,
      });
      this.proceedLoginWithUserdata(user);
      return "success";
    } catch (error: any) {
      console.error(error);
      this.apiErrorHandler.handleApiError(error);
    }
    return "social-login-error";
  }

  loginVk() {
    this.oidcService.loginVk();
  }
  async processVkResponse(): Promise<LoginResult> {
    try {
      const vkUser: any = await this.oidcService.processVkResponse();
      const user = await this.api.loginVk({
        email: vkUser.email,
        socialId: vkUser.sub,
        firstName: vkUser.firstName,
        lastName: vkUser.lastName,
        darkTheme: this.themeService.getTheme() === "dark",
        language: this.i18nService.getLocale(),
        companyUuid: this.companyUuid,
      });
      this.proceedLoginWithUserdata(user);
      return "success";
    } catch (error: any) {
      console.error(error);
      this.apiErrorHandler.handleApiError(error);
    }
    return "social-login-error";
  }

  async processOneTimeTokenLogin(token: string): Promise<LoginResult> {
    try {
      const response = await this.api.loginOneTimeToken(token);
      this.proceedLoginWithUserdata(response);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.WrongCredentials) {
        return "wrong-credentials";
      }
      return "one-time-token-error";
    }
  }

  async restorePassword(email: string): Promise<RestorePasswordResult> {
    try {
      await this.api.restorePassword(email, this.companyUuid);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.EmailNotFound) {
        return "email-not-found";
      }
      if (error === Errors.CannotRestorePassForSocialLogin) {
        return "cant-restore-pass-for-social-login";
      }
      return "network-error";
    }
  }

  async resetPassword(token: string, password: string): Promise<RestorePasswordResult> {
    try {
      await this.api.resetPassword(token, password);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.RestoreTokenNotFound) {
        return "restore-token-not-found";
      }

      return "network-error";
    }
  }

  private proceedLoginWithUserdata(user: LoginResponseDto) {
    this.tokenStorage.setToken(user.token);
    this.userStorage.saveUser(user);
    this.userStore.setUser(user);
    this.userStore.setBalance({
      credits: user.credits,
      goldCredits: user.goldCredits,
    });
    this.i18nService.saveLocale(user.language);
    this.themeService.saveThemeFromUser(user);
    this.userStore.setActiveSubscription(user.activeSubscription);
    this.companyService.refreshCompany();

    this.realtimeService.login();
  }
}
