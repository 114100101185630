<div class="container">
  <div *ngIf="!isCalculationShow; else calculator" class="content">
    <div *ngIf="!bookData; else bookPricing">
      <div class="header">
        <span class="text" i18n="@@pricing.calculator.panel.calculate-book">
          pricing.calculator.panel.calculate-book
        </span>
      </div>
    </div>

    <ng-template #bookPricing>
      <div class="header">
        <span class="text">{{ pluralizeCreditsNeed(bookPriceCredits) }}</span>

        <div class="price">
          <m-icon src="token.svg" size="s" color="--color-font-main" />
          <span>{{ bookPriceCredits }} {{ pluralizeCredits(bookPriceCredits) }}</span>
        </div>
      </div>

      <div class="divider"></div>

      <m-pricing-credits-calculator-panel-profit
        [profitPricePercent]="profitPricePercent()"
        [profitTimePercent]="profitTimePercent()"
        (onShowCalculationClick)="onShowCalculationButtonClick()"
      ></m-pricing-credits-calculator-panel-profit>

      <div class="divider"></div>

      <div *ngIf="isFileDropShow" class="header">
        <span class="text" i18n="@@pricing.calculator.panel.new-calculate-book">
          pricing.calculator.panel.new-calculate-book
        </span>
      </div>
    </ng-template>

    <m-file-drop *ngIf="isFileDropShow" #filedrop class="drop-zone" (dropped)="dropped($event)">
      <div class="drop-zone-content">
        <m-icon src="upload.svg" color="--color-font-main" size="original" />
        <div class="description-text">
          <span i18n="@@books.upload.drop-text1">books.upload.drop-text1</span>
          <br />
          <span i18n="@@books.upload.drop-text2">books.upload.drop-text2</span>
          <span
            class="mark"
            (click)="openFileSelector()"
            [style.color]="'var(--accent)'"
            i18n="@@books.upload.drop-text2-upload"
          >
            books.upload.drop_text2-upload
          </span>
          <span i18n="@@books.upload.drop-text3">books.upload.drop-text3</span>
        </div>
      </div>
    </m-file-drop>
  </div>

  <ng-template #calculator>
    <m-pricing-credits-calculator
      [bookData]="bookData"
      [result]="creditsCalculatorResultData"
      [userCredits]="userBalance.credits"
      [bookPriceCredits]="bookPriceCredits"
      [isLoading]="isCreditsCalculatorLoading"
      (onCalculateClick)="onCalculate($event)"
      (onBuyCreditsClick)="onBuyCreditsButtonClick($event)"
    ></m-pricing-credits-calculator>
    <svg-icon
      src="/assets/icons/notification-close.svg"
      class="close-calculator-btn"
      (click)="onCloseCalculationButtonClick()"
    ></svg-icon>
  </ng-template>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</div>

<!-- <m-modal *ngIf="isErrorModalVisible" (close)="closeErrorModal()" class="error-modal">
  <div class="modal-body">
    <span class="error-text">{{ errorText }}</span>
    <m-button button-style="primary" (onClick)="closeErrorModal()" i18n="@@dialog.ok-button">dialog.ok-button</m-button>
  </div>
</m-modal> -->
