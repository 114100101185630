import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  CheckSubscriptionToChannelTelegramResultData,
  ConnectTelegramDto,
  CreateTelegramProfileDto,
  CreateTelegramProfileResultData,
} from "@metranpage/user-data";

@Injectable({
  providedIn: "root",
})
export class ConnectTelegramApiService extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createOneTimeTelegramIntegrationToken() {
    return this.post<ConnectTelegramDto>("telegram-profile/create-one-time-telegram-integration-token", {});
  }

  async createTelegramProfile(data: CreateTelegramProfileDto) {
    return this.post<CreateTelegramProfileResultData>("telegram-profile/create-account", data);
  }

  async deleteTelegramProfile() {
    return this.delete<ConnectTelegramDto>("telegram-profile/delete-account");
  }

  async checkSubscriptionToChannel() {
    return this.post<CheckSubscriptionToChannelTelegramResultData>(
      "telegram-profile/check-subscription-to-channel",
      {},
    );
  }
}
