<m-ng-island [paddingSize]="16">
  <div class="container">
    <div *ngIf="moderatingImages.length > 0; else noPublishedImages" class="images">
      <m-c-image-grid class="grid" [items]="moderatingImages" [needClearPreviousOnChange]="true">
        <ng-template #itemTemplate let-item>
          <div class="grid-item">
            <img [src]="getUrlForImage(item)" class="image" (click)="onPreviewImageShowClick(item)" />
            <!-- <m-published-image [publishedImage]="item" (click)="onPreviewImageShowClick(item)" /> -->
          </div>
        </ng-template>
      </m-c-image-grid>

      <div *ngIf="isModeratingImagesLoading" class="loading"><m-loading-spinner @fadeInOut></m-loading-spinner></div>
    </div>

    <ng-template #noPublishedImages>
      <div class="no-results">
        <div class="header" i18n="@@admin.moderation.images.queue.no-images">
          admin.moderation.images.queue.no-images
        </div>
      </div>
    </ng-template>
  </div>
</m-ng-island>
