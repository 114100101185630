<m-ng-island [paddingSize]="0">
  <div class="container">
    <div class="header">
      <div class="header-group">
        <div i18n="@@user.profile.rewards-list.title">user.profile.rewards-list.title</div>
      </div>
    </div>

    <div class="divider-hor"></div>

    <div class="rewards-list-wrapper">
      <overlay-scrollbars>
        <div class="rewards-list">
          <div class="single-tasks">
            <m-reward-task-connect-telegram-view *ngIf="isTelegramConnectionAvailable()" [user]="user" />
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </div>
</m-ng-island>
