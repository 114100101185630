import { Inject, Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { UnisenderListKey, unisenderListKey } from "../../models/unisender-settings";

type UnisenderSettingsFormGroupData = {
  title: FormControl<UnisenderListKey>;
  listId: FormControl<number>;
  timeout: FormControl<number>;
};

type UnisenderGoSettingsFormGroupData = {
  language: FormControl<string>;
  templateIdVerificationEmail: FormControl<string>;
  templateIdRestorePassword: FormControl<string>;
  templateIdSubscribtionEndingNotice: FormControl<string>;
  templateIdFailPaymentNotice: FormControl<string>;
  templateIdForceFreeTariffNotice: FormControl<string>;
  templateIdTrialEndNotice: FormControl<string>;
};

type FormatLocalizationFormGroupData = {
  language: FormControl<string>;
  title: FormControl<string>;
};

@Injectable({
  providedIn: "root",
})
export class AdminUnisenderSettingsService {
  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[],
  ) {}

  getUnisenderListsKey() {
    return unisenderListKey;
  }

  createForm() {
    const unisenderSettingsControls: { [key: string]: FormGroup<UnisenderSettingsFormGroupData> } = {};
    const unisenderGoSettingsControls: { [key: string]: FormGroup<UnisenderGoSettingsFormGroupData> } = {};
    const localization: { [key: string]: FormGroup<FormatLocalizationFormGroupData> } = {};

    for (const ulk of unisenderListKey) {
      unisenderSettingsControls[ulk] = this.formBuilder.group({
        title: this.formBuilder.control(ulk, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        listId: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        timeout: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
      });
    }

    for (const { value } of this.availableLanguages) {
      unisenderGoSettingsControls[value] = this.formBuilder.group({
        language: this.formBuilder.control(value, {
          nonNullable: true,
          validators: [],
        }),
        templateIdVerificationEmail: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
        templateIdRestorePassword: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
        templateIdSubscribtionEndingNotice: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
        templateIdFailPaymentNotice: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
        templateIdForceFreeTariffNotice: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
        templateIdTrialEndNotice: this.formBuilder.control("", {
          nonNullable: true,
          validators: [],
        }),
      });

      localization[value] = this.formBuilder.group({
        language: this.formBuilder.control(value, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        title: this.formBuilder.control("", {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(/[\S]/g)],
        }),
      });
    }

    return this.formBuilder.group({
      // title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      landingUrl: new FormControl("", { nonNullable: true, validators: [] }),
      hostUrl: new FormControl("", { nonNullable: true, validators: [] }),
      knowledgeBaseUrl: new FormControl("", { nonNullable: true, validators: [] }),
      receiptTemplateForSubscription: new FormControl("", { nonNullable: true, validators: [] }),
      canProduceEpub: new FormControl(true, { nonNullable: true, validators: [Validators.required] }),
      isLogoutButtonAvailable: new FormControl(true, { nonNullable: true, validators: [] }),

      unisenderSettings: this.formBuilder.record(unisenderSettingsControls),
      unisenderGoSettings: this.formBuilder.record(unisenderGoSettingsControls),

      localization: this.formBuilder.record(localization),
    });
  }
}
