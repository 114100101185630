<div class="container">
  <div class="profit">
    <span i18n="@@pricing.calculator.panel.this-profit-1">pricing.calculator.panel.this-profit-1</span>
    <span>{{ profitPricePercent }}%</span>
    <span i18n="@@pricing.calculator.panel.this-profit-2">pricing.calculator.panel.this-profit-2</span>
    <span>{{ profitTimePercent }}% 🔥</span>
  </div>
  <span class="button" (click)="onShowCalculationButtonClick()" i18n="@@pricing.calculator.panel.show-calculation">
    pricing.calculator.panel.show-calculation
  </span>
</div>
