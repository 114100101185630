<m-modal position="center" [closeOnBackDropClick]="false" (close)="onCancelAddition.emit()">
  <form [formGroup]="form">
    <p><b i18n="@@admin.companies.add-company">admin.companies.add-company</b></p>
    <div class="controls">
      <div formGroupName="localization">
        <span i18n="@@admin.format.add-format.title">admin.format.add-format.title</span>

        <div class="localizations" *ngFor="let language of getLanguages()" [formGroupName]="language">
          <p class="section-title">{{ language.toUpperCase() }}</p>

          <div class="control">
            <m-ng-input formControlName="title" />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        (onClick)="createCompany()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.create"
      >
        admin.common.create
      </m-button>
    </div>
  </form>
</m-modal>
