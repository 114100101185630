import { Component } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-prompt-restriction",
  templateUrl: "./prompt-restriction.view.html",
  styleUrls: ["./prompt-restriction.view.scss"],
  animations: [slideInOutVertical],
})
export class PromptRestrictionView {}
