<div *ngIf="getCalculatedCredits()" class="content">
  <div class="header" i18n="@@pricing.calculator.result.heading">pricing.calculator.result.heading</div>

  <div class="result-market">
    <div class="column-name">
      <span class="market-name-text" i18n="@@pricing.calculator.result.market-average">
        pricing.calculator.result.market-average
      </span>
    </div>

    <div class="column-time">
      <div class="text-top" i18n="@@pricing.calculator.result.time">pricing.calculator.result.time</div>
      <span class="market-time-text">
        {{result?.market?.time?.value}}
        <span
          *ngIf="result?.market?.time?.units == 'hours'; else time_unit_minutes"
          i18n="@@pricing.calculator.result.time-unit-hours"
        >
          pricing.calculator.result.time-unit-hours
        </span>
      </span>
    </div>

    <div class="column-price">
      <div class="text-top" i18n="@@pricing.calculator.result.price">pricing.calculator.result.price</div>
      <span class="market-price-text">{{priceFormat(result?.market?.price)}}</span>
    </div>
  </div>

  <div class="result-metranpage">
    <div class="column-name">
      <span class="metranpage-name-text" i18n="@@pricing.calculator.result.service-average">
        pricing.calculator.result.service-average
      </span>
    </div>

    <div class="column-time">
      <div class="metranpage-time-text">
        {{result?.metranpage?.time?.value}}
        <span
          *ngIf="result?.metranpage?.time?.units == 'hours'; else time_unit_minutes"
          i18n="@@pricing.calculator.result.time-unit-hours"
        >
          pricing.calculator.result.time-unit-hours
        </span>
      </div>
      <div class="metranpage-profit" *ngIf="result!.profit.time || 0 > 0">
        <span class="metranpage-profit__arrow-down">↓</span>
        {{result?.profit?.time}} %
      </div>
    </div>

    <div class="column-price">
      <div *ngIf="result!.metranpage.price == 0" class="metranpage-price-text" i18n="@@pricing.calculator.for-free">
        pricing.calculator.for-free
      </div>
      <div *ngIf="result!.metranpage.price > 0" class="metranpage-price-text">
        {{priceFormat(result?.metranpage?.price)}}
      </div>
      <div class="metranpage-profit" *ngIf="result!.profit.price > 0">
        <span class="metranpage-profit__arrow-down">↓</span>
        {{result?.profit?.price}} %
      </div>
      <m-button
        *ngIf="isPayButtonShow()"
        class="button-buy"
        button-style="primary"
        (onClick)="onBuyCreditsButtonClick(creditsNotEnough())"
      >
        <div class="button-text">
          <span class="indent-right" i18n="@@pricing.calculator.result.pay">pricing.calculator.result.pay</span>
        </div>
      </m-button>
    </div>
  </div>
</div>

<ng-template #time_unit_minutes>
  <span i18n="@@pricing.calculator.result.time-unit-minutes">pricing.calculator.result.time-unit-minutes</span>
</ng-template>
