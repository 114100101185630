<div class="notifications-group">
  <m-account-rewards-icon-view [user]="user" />
  <m-account-notifications-icon-view [user]="user" />
</div>

<div class="group">
  <div *ngIf="userBalance" class="tokens">
    <div class="token-group">
      <div class="token-balance">
        <m-icon src="/assets/img/app-bar-menu/token-gold.svg" size="s" color="--accent" />
        <span class="credit-value">{{ userBalance.goldCredits }}</span>
      </div>
      <m-icon
        *ngIf="isPurchasesAvailable"
        class="token-action"
        src="/assets/img/app-bar-menu/tokens-info.svg"
        color="--color-bg-app-bar-menu-token-action"
        size="original"
        (click)="onTokenGoldInfoShow()"
      />
    </div>
    <div *ngIf="isPurchasesAvailable" class="token-group">
      <div class="token-balance">
        <m-icon src="/assets/img/app-bar-menu/token-simple-{theme}.svg" size="s" />
        <span class="credit-value">{{ getUserBalance() }}</span>
      </div>
      <m-icon
        class="token-action"
        src="/assets/img/app-bar-menu/tokens-plus.svg"
        color="--color-bg-app-bar-menu-token-action"
        size="original"
        (click)="onTokenSimpleAddClick()"
      />
    </div>
  </div>

  <div class="menu-open-button" (click)="toggleMenu()">
    <m-icon src="/assets/img/user.svg" color="--color-font-main" size="m" />
    <m-icon src="/assets/img/app-bar-menu/app-bar-menu-arrow.svg" color="--color-font-input" size="original"></m-icon>
  </div>
</div>

<m-card *ngIf="isMenuOpened" class="menu">
  <a class="menu-item" routerLink="/account" (click)="closeMenu()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/account.svg" color="--color-font-input" size="m"></m-icon>
      <span>{{ user.firstName }}</span>
    </div>
  </a>

  <div class="divider"></div>

  <a class="menu-item" routerLink="/books" (click)="closeMenu()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/menu-projects.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.my-projects-button">app-bar.my-projects-button</span>
    </div>
  </a>

  <a *ngIf="isImagesGenerationAvailable" class="menu-item" routerLink="/image-generation" (click)="closeMenu()">
    <div class="elements-group">
      <m-icon src="image-generation.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.image-generation-button">app-bar.image-generation-button</span>
    </div>
  </a>

  <a *ngIf="isPurchasesAvailable" class="menu-item" routerLink="/payments/subscription" (click)="closeMenu()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/payments.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.profile.menu.pricing-button">app-bar.profile.menu.pricing-button</span>
    </div>
  </a>

  <a class="menu-item" (click)="showPromocodeModal()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/promocode.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.profile.menu.promocode-button">app-bar.profile.menu.promocode-button</span>
    </div>
  </a>

  <div class="divider"></div>

  <a *ngIf="isOnboardingAvailable" class="menu-item" (click)="startOnboarding()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/onboarding.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.profile.menu.start-onboarding-button">app-bar.profile.menu.start-onboarding-button</span>
    </div>
  </a>

  <a *ngIf="knowledgeBaseUrl" class="menu-item" [href]="knowledgeBaseUrl" target="_blank">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/knowledge-base.svg" color="--color-font-input" size="m"></m-icon>
      <span i18n="@@app-bar.profile.menu.knowledge-base-button">app-bar.profile.menu.knowledge-base-button</span>
    </div>
  </a>

  <a *ngIf="isLanguageSwitchAvailable" class="menu-item not-clickable">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/language.svg" color="--color-font-input" size="m"></m-icon>
      <span class="" i18n="@@app-bar.profile.menu.language-button">app-bar.profile.menu.language-button</span>
    </div>
    <div class="elements-group">
      <m-lang-select-2
        [userLanguage]="user.language"
        select-style="small"
        (changeLang)="onLanguageChangeRequested($event)"
      />
    </div>
  </a>

  <a class="menu-item" (click)="onThemeSwitchToggle()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/color-scheme.svg" color="--color-font-input" size="m"></m-icon>
      <span class="" i18n="@@app-bar.profile.menu.dark-theme-button">app-bar.profile.menu.dark-theme-button</span>
    </div>
    <m-switch
      [(ngModel)]="user.darkTheme"
      [isInteractive]="false"
      [withLabel]="false"
      class="elements-group"
    ></m-switch>
  </a>

  <div class="divider"></div>

  <a *ngIf="isLogoutButtonAvailable" class="menu-item danger" (click)="onLogoutButtonClick()">
    <div class="elements-group">
      <m-icon src="/assets/img/app-bar-menu/logout.svg" color="--color-font-text-danger" size="m"></m-icon>
      <span class="" i18n="@@app-bar.profile.menu.logout-button">app-bar.profile.menu.logout-button</span>
    </div>
  </a>
</m-card>

<m-modal
  *ngIf="isAboutGoldCreditsVisible"
  position="center"
  [closeButtonVisible]="false"
  (close)="onTokenGoldInfoClose()"
>
  <div class="modal-container">
    <div class="info-block">
      <span class="info-header" i18n="@@books.preview.payments-modal.what-is-gold-credits">
        books.preview.payments-modal.what-is-gold-credits
      </span>
      <span class="info-text" i18n="@@books.preview.payments-modal.what-is-gold-credits-text">
        books.preview.payments-modal.what-is-gold-credits-text
      </span>
    </div>
    <div class="info-block">
      <span class="info-header" i18n="@@books.preview.payments-modal.how-to-get-gold-credits">
        books.preview.payments-modal.how-to-get-gold-credits
      </span>
      <div>
        <m-localized-text
          class="info-text"
          text="books.preview.payments-modal.how-to-get-gold-credits-text"
          i18n-text="@@books.preview.payments-modal.how-to-get-gold-credits-text"
        />
      </div>
    </div>
    <div class="info-block">
      <m-button
        button-style="primary"
        size="full-width"
        (onClick)="onTokenGoldInfoClose()"
        i18n="@@dialog.understand-button"
      >
        dialog.understand-button
      </m-button>
    </div>
  </div>
</m-modal>

<m-promocode-modal
  *ngIf="isPromocodeModalVisible"
  (onPromocodeEnter)="promocodeEnter($event)"
  (onClose)="hidePromocodeModal()"
/>
