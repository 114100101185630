<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <m-ng-island [paddingSize]="24">
    <div class="controls">
      <div class="section-title" i18n="@@admin.companies.company.telegram-settings.tilte">
        admin.companies.company.telegram-settings.tilte
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.telegram-settings.can-connect">
          admin.companies.company.telegram-settings.can-connect
        </span>
        <m-switch formControlName="canConnect" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.telegram-settings.channel-id">
          admin.companies.company.telegram-settings.channel-id
        </span>
        <m-ng-input formControlName="channelId" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.telegram-settings.channel-url">
          admin.companies.company.telegram-settings.channel-url
        </span>
        <m-ng-input formControlName="channelUrl" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.telegram-settings.bot-url">
          admin.companies.company.telegram-settings.bot-url
        </span>
        <m-ng-input formControlName="botUrl" />
      </div>
    </div>

    <div class="row">
      <m-button
        button-style="primary"
        [is-disabled]="isDisabled()"
        (onClick)="onSubmit()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </m-ng-island>
</form>
