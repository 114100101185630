import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyTelegramSettings } from "../../models/company";
import { AdminCompanyTelegramSettingsApi } from "./companies-telegram-settings.api";
import { AdminCompanyTelegramSettingsStore } from "./companies-telegram-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyTelegramSettingsService {
  constructor(
    private readonly api: AdminCompanyTelegramSettingsApi,
    private readonly store: AdminCompanyTelegramSettingsStore,
  ) {}

  async getTelegramSettings() {
    return await this.api.getTelegramSettings();
  }

  async loadTelegramSettings() {
    const settings = await this.api.getTelegramSettings();
    this.store.setSettings(settings);
  }

  async updateTelegramSettings(data: CompanyTelegramSettings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateTelegramSettings(data);
      this.store.setSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }
}
