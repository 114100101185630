<div class="backdrop" (click)="close.emit()"></div>
<m-card>
  <m-icon class="close-icon" src="close-cross.svg" color="--color-font-main" size="xs" (click)="close.emit()"></m-icon>

  <div class="content">
    <div class="header">
      <span i18n="@@pricing.info-panel.modal.header-1">pricing.info-panel.modal.header-1</span>
      <span class="rest">1</span>
      <div>
        <span class="indent-right" i18n="@@pricing.info-panel.modal.header-2">pricing.info-panel.modal.header-2</span>
        <span>{{ price }}</span>
      </div>
    </div>

    <m-localized-text
      text="@@pricing.info-panel.modal.body"
      i18n-text="@@pricing.info-panel.modal.body"
    ></m-localized-text>

    <div class="images-and-tables">
      <div class="sub-block">
        <span class="modal-text">1</span>
        <m-icon src="image.svg" color="--color-font-main" size="m" class="block-icon" />
        <span class="modal-text">{{ imageCoeff }}</span>
      </div>

      <div class="sub-block">
        <span class="modal-text">1</span>
        <m-icon src="table.svg" color="--color-font-main" size="m" class="block-icon" />
        <span class="modal-text">{{ tableCoeff }}</span>
      </div>
    </div>
  </div>

  <div class="footer">
    <m-localized-text
      text="@@pricing.info-panel.modal.footer"
      i18n-text="@@pricing.info-panel.modal.footer"
    ></m-localized-text>
  </div>
</m-card>
