<div class="row-gold-credits">
  <span class="indent-right" i18n="@@pricing.calculator.panel.use-gold-credit">
    pricing.calculator.panel.use-gold-credit
  </span>
  <m-button
    class="button-use-gold-credit"
    button-style="primary"
    color-theme="gold"
    (click)="onPayGoldCreditsButtonClick(1)"
    i18n="@@pricing.calculator.panel.gold-credit"
  >
    pricing.calculator.panel.gold-credit
  </m-button>
</div>
