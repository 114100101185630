import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { filterUndefined } from "@metranpage/core";
import { Company } from "../../models/company";
import { AdminCompanyStore } from "../../services/company/company.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-company-page",
  templateUrl: "./company.page.html",
  styleUrls: ["./company.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCompanyPage extends AdminBasePage {
  company?: Company;

  isEditModalVisible = false;

  constructor(
    private readonly adminCompanyStore: AdminCompanyStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.addSub(
      this.adminCompanyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.company = company;

          this.cdr.detectChanges();
        }),
    );
  }

  onEditSave(company: Company) {
    this.isEditModalVisible = false;
  }

  onEditClick() {
    this.isEditModalVisible = true;
  }

  onEditCancel() {
    this.isEditModalVisible = false;
  }

  onEditError(error: unknown) {}
}
