<m-ng-island [paddingSize]="24">
  <form *ngIf="settings && form; else noSettings" [formGroup]="form" class="controls-loc">
    <div *ngIf="hasEmptyField()" class="warning" i18n="@@admin.companies.company.edition-notice-empty-fields">
      admin.companies.company.edition-notice-empty-fields
    </div>

    <div *ngFor="let s of settings; let i = index" class="controls">
      <div [formGroupName]="s.lang" class="controls-group">
        <span class="lang">{{ s.lang }}</span>

        <div class="control">
          <span class="label" i18n="@@admin.companies.company.edition-notice.legel-name">
            admin.companies.company.edition-notice.legel-name
          </span>
          <m-ng-input formControlName="legalName" />
        </div>

        <div class="control">
          <span class="label" i18n="@@admin.companies.company.edition-notice.project-name">
            admin.companies.company.edition-notice.project-name
          </span>
          <m-ng-input formControlName="projectName" />
        </div>

        <div class="control">
          <span class="label" i18n="@@admin.companies.company.edition-notice.project-description">
            admin.companies.company.edition-notice.project-description
          </span>
          <m-ng-input formControlName="projectDescription" />
        </div>

        <div class="control">
          <span class="control-title" i18n="@@admin.companies.company.edition-notice-settings.template">
            admin.companies.company.edition-notice-settings.template
          </span>
          <div class="control-content">
            <m-file-drop
              #filedropTemplate
              (dropped)="dropped($event, s.lang, 'template')"
              style="--custom-height: 256px; flex-shrink: 0"
              accept=".idms"
            >
              <div class="drop-placeholder">
                <div>
                  <p class="description-text">
                    <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
                  </p>
                  <p class="description-text">
                    &nbsp;
                    <span
                      class="mark"
                      (click)="openFileSelector(i, 'template')"
                      [style.color]="'#e02379'"
                      i18n="@@books.upload.drop-text2-upload"
                    >
                      books.upload.drop_text2_upload
                    </span>
                  </p>
                </div>
              </div>
            </m-file-drop>

            <div *ngIf="s.templateSrc" class="uploded-file">
              <span>{{ s.templateSrc }}</span>
              <m-icon
                src="book-delete.svg"
                size="m"
                color="--color-error"
                class="delete"
                (click)="deleteFile(s, 'template')"
              ></m-icon>
            </div>
          </div>
        </div>

        <div class="control">
          <span class="control-title" i18n="@@admin.companies.company.edition-notice-settings.template-small">
            admin.companies.company.edition-notice-settings.template-small
          </span>

          <div class="control-content">
            <m-file-drop
              #filedropTemplateSmall
              (dropped)="dropped($event, s.lang, 'template-small')"
              style="--custom-height: 256px; flex-shrink: 0"
              accept=".idms"
            >
              <div class="drop-placeholder">
                <div>
                  <p class="description-text">
                    <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
                  </p>
                  <p class="description-text">
                    &nbsp;
                    <span
                      class="mark"
                      (click)="openFileSelector(i, 'template-small')"
                      [style.color]="'#e02379'"
                      i18n="@@books.upload.drop-text2-upload"
                    >
                      books.upload.drop_text2_upload
                    </span>
                  </p>
                </div>
              </div>
            </m-file-drop>

            <div *ngIf="s.templateSmallSrc" class="uploded-file">
              <span>{{ s.templateSmallSrc }}</span>
              <m-icon
                src="book-delete.svg"
                size="m"
                color="--color-error"
                class="delete"
                (click)="deleteFile(s, 'template-small')"
              ></m-icon>
            </div>
          </div>
        </div>

        <div class="control">
          <span class="control-title" i18n="@@admin.companies.company.edition-notice-settings.preview">
            admin.companies.company.edition-notice-settings.preview
          </span>

          <div class="control-content">
            <m-file-drop
              #filedropPreview
              (dropped)="dropped($event, s.lang, 'template-preview')"
              style="--custom-height: 256px; flex-shrink: 0"
              accept="image/*"
            >
              <div class="drop-placeholder">
                <div>
                  <p class="description-text">
                    <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
                  </p>
                  <p class="description-text">
                    &nbsp;
                    <span
                      class="mark"
                      (click)="openFileSelector(i, 'template-preview')"
                      [style.color]="'#e02379'"
                      i18n="@@books.upload.drop-text2-upload"
                    >
                      books.upload.drop_text2_upload
                    </span>
                  </p>
                </div>
              </div>
            </m-file-drop>

            <div *ngIf="s.preview" class="preview">
              <img [src]="s.preview" alt="" />
              <m-icon
                src="book-delete.svg"
                size="m"
                color="--color-error"
                class="delete-preview"
                (click)="deleteFile(s, 'template-preview')"
              ></m-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="primary" [is-disabled]="!form.valid" (onClick)="onSaveClick()" i18n="@@admin.common.save">
        admin.common.save
      </m-button>
    </div>
  </form>

  <ng-template #noSettings>No settings loaded</ng-template>
</m-ng-island>
