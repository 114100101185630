<!-- <div class="container"> -->
<m-ng-island [paddingSize]="0">
  <m-pricing-nav-view alignTabs="center" />

  <div class="tariff-active">
    <m-tariff-active-card
      *ngIf="activeSubscription"
      [activeSubscription]="activeSubscription"
      (onTariffSettingsClick)="onTariffSettingsClick.emit($event)"
    />
  </div>
</m-ng-island>

<m-ng-island [paddingSize]="24" class="tariffs-grid">
  <div *ngIf="tariffsPeriods.length > 0" class="title-group">
    <m-localized-text class="title" text="pricing.tariffs.title" i18n-text="@@pricing.tariffs.title" />

    <m-tariff-period-selector
      [tariffsPeriods]="tariffsPeriods"
      [activeTariffPeriod]="activeTariffPeriod"
      (onPeriodSelect)="onPeriodSelectClick.emit($event)"
    />

    <m-promocode-enter-menu
      [promocodeResult]="promocodeResult"
      [promocodeDiscountData]="promocodeDiscountData"
      (promocodeEnter)="onPromocodeEnter($event)"
    />
  </div>

  <m-tariff-grid
    *ngIf="tariffs.length > 0"
    cardAppearance="full"
    [tariffs]="tariffs"
    [activeSubscription]="activeSubscription"
    [promocodeDiscountData]="promocodeDiscountData"
    (buySubscription)="onBuySubscriptionClick.emit($event)"
  />

  <div *ngIf="tariffs.length > 0 && faqs.length > 0" class="faqs-wrapper">
    <div class="faqs-wrapper-title">FAQ</div>
    <div class="faqs">
      <m-faq *ngFor="let faq of faqs" [faq]="faq" />
    </div>
  </div>
</m-ng-island>
<!-- </div> -->
