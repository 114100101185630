import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-pricing-credits-calculator-panel-gold-credit',
  templateUrl: './calculator-panel-gold-credit.view.html',
  styleUrls: ['./calculator-panel-gold-credit.view.scss'],
})
export class CreditsCalculatorGoldCreditView {
  @Output()
  onPayGoldCreditClick = new EventEmitter<number>();

  protected onPayGoldCreditsButtonClick(goldCredits: number) {
    this.onPayGoldCreditClick.emit(goldCredits);
  }
}
