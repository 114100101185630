<m-card class="panel">
  <div class="content">
    <div class="header" i18n="@@pricing.calculator.heading">pricing.calculator.heading</div>

    <form [formGroup]="form" class="steps">
      <div class="step" [class.bottom-border]="getCalculatedCredits()">
        <svg-icon src="/assets/editor/toolbar-block-add-text.svg" class="icon-top"></svg-icon>
        <div class="icon-right">+</div>
        <div class="step-content">
          <div i18n="@@pricing.calculator.text-count">pricing.calculator.text-count</div>
          <div>
            <m-ng-input
              class="input-field"
              name="textCount"
              formControlName="textCount"
              text-align="center"
            />
          </div>
          <div class="errors-field">
            <m-input-field-error
              *ngIf="this.form.get('textCount')?.errors?.['pattern']"
              error="not-digits"
            ></m-input-field-error>
            <m-input-field-error
              *ngIf="this.form.get('textCount')?.errors?.['min']"
              error="eq-zero"
            ></m-input-field-error>
          </div>
        </div>
      </div>

      <div class="step" [class.bottom-border]="getCalculatedCredits()">
        <svg-icon src="/assets/editor/toolbar-block-add-image.svg" class="icon-top"></svg-icon>
        <div class="icon-right">+</div>
        <div class="step-content">
          <div i18n="@@pricing.calculator.images-count">pricing.calculator.images-count</div>
          <div>
            <m-ng-input
              class="input-field"
              name="imagesCount"
              formControlName="imagesCount"
              text-align="center"
            />
          </div>
          <div class="errors-field">
            <m-input-field-error
              *ngIf="this.form.get('imagesCount')?.errors?.['pattern']"
              error="not-digits"
            ></m-input-field-error>
            <m-input-field-error
              *ngIf="this.form.get('imagesCount')?.errors?.['min']"
              error="lt-zero"
            ></m-input-field-error>
          </div>
        </div>
      </div>

      <div class="step" [class.bottom-border]="getCalculatedCredits()">
        <svg-icon src="/assets/editor/toolbar-block-add-table.svg" class="icon-top"></svg-icon>
        <div class="icon-right">=</div>
        <div class="step-content">
          <div i18n="@@pricing.calculator.tables-count">pricing.calculator.tables-count</div>
          <div>
            <m-ng-input
              class="input-field"
              name="tablesCount"
              formControlName="tablesCount"
              text-align="center"
            />
          </div>
          <div class="errors-field">
            <m-input-field-error
              *ngIf="this.form.get('tablesCount')?.errors?.['pattern']"
              error="not-digits"
            ></m-input-field-error>
            <m-input-field-error
              *ngIf="this.form.get('tablesCount')?.errors?.['min']"
              error="lt-zero"
            ></m-input-field-error>
          </div>
        </div>
      </div>

      <div class="step" [class.bottom-border]="getCalculatedCredits()">
        <div class="step-content-credits">
          <div class="step-content-heading" i18n="@@pricing.calculator.credits-count">
            pricing.calculator.credits-count
          </div>
          <div *ngIf="getCalculatedCredits()" class="step-content-result">{{getCalculatedCredits()}}</div>
          <div
            *ngIf="getCalculatedCredits() == 0"
            class="step-content-result-free"
            i18n="@@pricing.calculator.for-free"
          >
            pricing.calculator.for-free
          </div>
          <m-button
            *ngIf="isCalculateButtonVisible()"
            class="button-calculate"
            button-style="primary"
            (onClick)="onCalculateButtonClick()"
            [is-disabled]="isCalculateButtonDisabled()"
            i18n="@@pricing.calculator.calculate"
          >
            pricing.calculator.calculate
          </m-button>
          <m-button
            *ngIf="isRecalculateButtonVisible()"
            class="button-recalculate"
            button-style="tertiary"
            (click)="onCalculateButtonClick()"
            [is-disabled]="isCalculateButtonDisabled()"
            i18n="@@pricing.calculator.recalculate"
          >
            pricing.calculator.recalculate
          </m-button>
        </div>
      </div>
    </form>

    <m-pricing-credits-calculator-result
      [result]="result"
      [userCredits]="userCredits"
      [bookPriceCredits]="bookPriceCredits"
      (onBuyCreditsClick)="onBuyCreditsButtonClick($event)"
    ></m-pricing-credits-calculator-result>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-card>
