import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { BookDataStep } from "@metranpage/book-data";
import { IS_EDITION_NOTICE_AVAILABLE } from "@metranpage/core";

@Component({
  selector: "m-book-edit-modal",
  templateUrl: "./book-edit-modal.view.html",
  styleUrls: ["./book-edit-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookEditModalView {
  @Output()
  onEditClick = new EventEmitter<BookDataStep>();

  constructor(@Inject(IS_EDITION_NOTICE_AVAILABLE) protected readonly isEditionNoticeAvailable: boolean) {}

  navigateToEditMenu(step: BookDataStep) {
    this.onEditClick.emit(step);
  }
}
