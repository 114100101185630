import { Component } from "@angular/core";
import { I18nService } from "@metranpage/i18n";

@Component({
  selector: "m-debug-locale",
  template: `
    <div class="langs">
      <table>
        <tr>
          <td>navigator.language</td>
          <td>{{ browserLocale }}</td>
        </tr>
        <tr>
          <td>navigator.languages</td>
          <td>{{ browserLocales }}</td>
        </tr>
        <tr>
          <td>detected</td>
          <td>{{ detectedLocale }}</td>
        </tr>
        <tr>
          <td>saved</td>
          <td>
            {{ savedLocale }}
            <button (click)="clearSaved()" style="text-decoration: underline;">clear saved</button>
          </td>
        </tr>
        <tr>
          <td>final</td>
          <td>
            {{ finalLocale }}
          </td>
        </tr>
      </table>
    </div>
  `,
  styles: [
    `
      .langs {
        margin-top: 16px;
        font-size: 12px;
      }
    `,
  ],
})
export class DebugLocaleDetectionComponent {
  browserLocale: string;
  browserLocales: string;
  detectedLocale: string;
  savedLocale: string;
  finalLocale: string;

  constructor(i18n: I18nService) {
    this.browserLocale = navigator.language;
    this.browserLocales = JSON.stringify(navigator.languages);
    this.detectedLocale = i18n.getBrowserLocale();
    this.savedLocale = i18n.getSavedLocale() || "none";
    this.finalLocale = i18n.getLocale();
  }

  clearSaved() {
    localStorage.removeItem("metranpage-locale");
    localStorage.removeItem("mpg_locale");
    location.reload();
  }
}
