<m-ng-island [paddingSize]="24">
  <form *ngIf="!!referalLink; else noreferalLink" [formGroup]="form">
    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.referal-links.referal-link.token">
          admin.referal-links.referal-link.token
        </span>

        <div class="group">
          <m-ng-input formControlName="token" />
          <m-icon
            title="common.copy-text"
            i18n-title="@@common.copy-text"
            class="icon"
            size="s"
            src="plus.svg"
            color="--color-font-main"
            (click)="onGenerateToken()"
          />
        </div>
      </div>

      <div class="ref-link-preview">
        <span class="ref-link">{{referalLinkUrl}}</span>
        <m-icon
          title="common.copy-text"
          i18n-title="@@common.copy-text"
          class="icon"
          size="s"
          src="copy.svg"
          color="--color-font-main"
          (click)="onCopyReferalLinkUrl()"
        />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.referal-links.referal-link.agent-fee">
          admin.referal-links.referal-link.agent-fee
        </span>
        <m-ng-input-number formControlName="agentFee" />
      </div>
    </div>

    <div class="bottom-bar">
      <div class="buttons">
        <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" [is-disabled]="!form.valid" (onClick)="onSave()" i18n="@@admin.common.save">
          admin.common.save
        </m-button>
      </div>
    </div>
  </form>
  <ng-template #noreferalLink>No referalLink loaded</ng-template>
</m-ng-island>
