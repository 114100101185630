<m-ng-island class="context-menu" [paddingSize]="8">
  <m-markup-editor-text-generation-context-menu-button
    iconSrc="markup-context-menu-image-generation.svg"
    text="editor.context-menu.ai.image-generation"
    i18n-text="@@editor.context-menu.ai.image-generation"
    [hasNewMark]="true"
    (click)="click('image-generation')"
  />
  <m-markup-editor-text-generation-context-menu-button
    iconSrc="markup-context-menu-upgrade-text.svg"
    text="editor.context-menu.ai.upgrade-text"
    i18n-text="@@editor.context-menu.ai.upgrade-text"
    (click)="click('upgrade-text')"
  />
  <m-markup-editor-text-generation-context-menu-button
    iconSrc="markup-context-menu-check-grammar.svg"
    text="editor.context-menu.ai.check-grammar"
    i18n-text="@@editor.context-menu.ai.check-grammar"
    (click)="click('check-grammar')"
  />
  <m-markup-editor-text-generation-context-menu-button
    iconSrc="markup-context-menu-increase-text.svg"
    text="editor.context-menu.ai.increase-text"
    i18n-text="@@editor.context-menu.ai.increase-text"
    (click)="click('increase-text')"
  />
  <m-markup-editor-text-generation-context-menu-button
    iconSrc="markup-context-menu-decrease-text.svg"
    text="editor.context-menu.ai.decrease-text"
    i18n-text="@@editor.context-menu.ai.decrease-text"
    (click)="click('decrease-text')"
  />
  <m-markup-editor-text-generation-context-menu-button
    class="tone-menu-host"
    iconSrc="markup-context-menu-tone.svg"
    text="editor.context-menu.ai.tone"
    i18n-text="@@editor.context-menu.ai.tone"
    [hasSubMenuIcon]="true"
  >
    <m-ng-island class="tone-menu" [paddingSize]="8">
      <m-markup-editor-text-generation-context-menu-button
        iconSrc="markup-context-menu-tone-professional.svg"
        text="editor.context-menu.ai.tone.professional"
        i18n-text="@@editor.context-menu.ai.tone.professional"
        (click)="click('tone-professional')"
      />
      <m-markup-editor-text-generation-context-menu-button
        iconSrc="markup-context-menu-tone-normal.svg"
        text="editor.context-menu.ai.tone.normal"
        i18n-text="@@editor.context-menu.ai.tone.normal"
        (click)="click('tone-normal')"
      />
      <m-markup-editor-text-generation-context-menu-button
        iconSrc="markup-context-menu-tone-normal.svg"
        text="editor.context-menu.ai.tone.straightforward"
        i18n-text="@@editor.context-menu.ai.tone.straightforward"
        (click)="click('tone-straightforward')"
      />
      <m-markup-editor-text-generation-context-menu-button
        iconSrc="markup-context-menu-tone-normal.svg"
        text="editor.context-menu.ai.tone.confident"
        i18n-text="@@editor.context-menu.ai.tone.confident"
        (click)="click('tone-confident')"
      />
      <m-markup-editor-text-generation-context-menu-button
        iconSrc="markup-context-menu-tone-friendly.svg"
        text="editor.context-menu.ai.tone.friendly"
        i18n-text="@@editor.context-menu.ai.tone.friendly"
        (click)="click('tone-friendly')"
      />
    </m-ng-island>
  </m-markup-editor-text-generation-context-menu-button>
</m-ng-island>
