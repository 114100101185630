import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Reward } from "../models/reward";

export type RewardState = {
  unreadRewardsCount: number;
  rewards: Reward[];
  rewardsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class RewardStore extends Store<RewardState> {
  protected override getInitialState(): RewardState {
    return {
      unreadRewardsCount: 0,
      rewards: [],
      rewardsPageCount: 1,
    };
  }

  getUnreadRewardsCountObservable() {
    return this.getFieldObservable("unreadRewardsCount");
  }

  getUnreadRewardsCount() {
    return this.getField("unreadRewardsCount");
  }

  getRewardsObservable() {
    return this.getFieldObservable("rewards");
  }

  getRewards() {
    return this.getField("rewards");
  }

  getPageCountObservable() {
    return this.getFieldObservable("rewardsPageCount");
  }

  setUnreadRewardsCount(unreadRewardsCount: number) {
    this.update((state) => ({ ...state, unreadRewardsCount }));
  }

  setRewards(rewards: Reward[]) {
    this.update((state) => ({ ...state, rewards }));
  }

  setRewardsPageCount(count: number) {
    this.update((state) => ({ ...state, rewardsPageCount: count }));
  }

  addReward(reward: Reward) {
    this.update((state) => {
      const storeReward = state.rewards.find((n) => n.id === reward.id);
      if (storeReward) {
        return state;
      }

      return {
        ...state,
        rewards: [reward].concat(state.rewards),
        unreadRewardsCount: state.unreadRewardsCount + 1,
      };
    });
  }

  addRewardsToStart(rewards: Reward[]) {
    this.update((state) => {
      const newRewards: Reward[] = [];
      for (const reward of rewards) {
        const storeReward = state.rewards.find((ig) => ig.id === reward.id);
        if (storeReward) {
          continue;
        }
        newRewards.push(reward);
      }

      return {
        ...state,
        rewards: newRewards.concat(state.rewards),
      };
    });
  }

  addRewardsToEnd(rewards: Reward[]) {
    this.update((state) => {
      const newRewards: Reward[] = [];
      for (const reward of rewards) {
        const storeReward = state.rewards.find((ig) => ig.id === reward.id);
        if (storeReward) {
          continue;
        }
        newRewards.push(reward);
      }

      return {
        ...state,
        rewards: state.rewards.concat(newRewards),
      };
    });
  }

  updateReward(updatedReward: Reward) {
    const rewards = this.getRewards();
    const reward = rewards.find((n) => n.id === updatedReward.id);

    if (reward) {
      this.update((state) => {
        const updated = state.rewards.map((n) => {
          if (n.id === updatedReward.id) {
            return updatedReward;
          }
          return n;
        });

        return {
          ...state,
          rewards: updated,
        };
      });
    } else {
      this.addReward(updatedReward);
    }
  }
}
