import { Component } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { NotificationAddTokens } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-add-tokens-card-view",
  templateUrl: "account-notification-add-tokens-card.view.html",
  styleUrls: ["account-notification-add-tokens-card.view.scss"],
})
export class AccountNotificationAddTokensCardView extends AccountNotificationBaseCardView<NotificationAddTokens> {
  constructor(private readonly i18nService: I18nService) {
    super();
  }

  protected getText() {
    let text = "";

    if (this.payload.purpose === "bonus") {
      text += `${$localize`:@@user.profile.notifications.card.add-tokens.purpose.bonus:`} `;
    }

    const creditsCount = this.payload.creditsCount;
    if (creditsCount) {
      text += `${creditsCount} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, {
        credits: creditsCount,
      })}`;
    }

    if (this.payload.goldCreditsCount) {
      text += `${this.payload.goldCreditsCount} ${this.i18nService.pluralize(
        $localize`:@@pricing.pluralize-gold-credits:`,
        {
          credits: this.payload.goldCreditsCount,
        },
      )}`;
    }

    text += ` ${this.i18nService.pluralize($localize`:@@common.pluralize.add:`, {
      value: creditsCount + this.payload.goldCreditsCount,
    })} ${$localize`:@@user.profile.notifications.card.add-tokens.description-1:`}`;

    return text;
  }
}
