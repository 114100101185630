import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { FeatureToggle } from "../models/feature-toggle";

export type State = {
  toggles: FeatureToggle[];
};

@Injectable({
  providedIn: "root",
})
export class FeatureToggleAdminStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      toggles: [],
    };
  }

  getFeatureTogglesObservable() {
    return this.getFieldObservable("toggles");
  }

  setFeatureToggles(toggles: FeatureToggle[]) {
    this.update((state) => ({ ...state, toggles }));
  }
}
