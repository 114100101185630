import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-pricing-credits-calculator-panel-profit',
  templateUrl: './calculator-panel-profit.view.html',
  styleUrls: ['./calculator-panel-profit.view.scss'],
})
export class CreditsCalculatorProfitView {
  @Input()
  profitPricePercent!: number;
  @Input()
  profitTimePercent!: number;

  @Output()
  onShowCalculationClick = new EventEmitter();

  protected onShowCalculationButtonClick() {
    this.onShowCalculationClick.emit();
  }
}
