import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'm-age-restrictions-modal',
  templateUrl: './age-restrictions-modal.view.html',
  styleUrls: ['./age-restrictions-modal.view.scss'],
})
export class AgeRestrictionsModalView {
  @Output()
  ageSelected = new EventEmitter<number>();

  @Output()
  close = new EventEmitter();

  selectAge(age: number) {
    this.ageSelected.emit(age);
    this.close.emit();
  }
}
