<div
  class="container"
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScrollEvent()"
>
  <div class="preview">
    <m-ng-island class="preview-group">
      <m-filter-switch
        [options]="filterOptions"
        [selectedOptionId]="filterPublishedFrom"
        (onSelect)="onFilterChanged($event)"
      />

      <div class="divider"></div>

      <m-moderation-image-preview
        #previewImageElement
        [previewImage]="previewModerationImageRequest?.generatedImage"
        [hasPrevious]="hasPreviousImage()"
        [hasNext]="hasNextImage()"
        (onPrevious)="onPrevious()"
        (onNext)="onNext()"
      />
    </m-ng-island>

    <m-admin-moderation-images-queue
      [moderatingImages]="moderatingImages"
      (onSelect)="onSelectModerationImageRequest($event)"
    />
  </div>

  <div class="sidebar-right">
    <m-ng-island [paddingSize]="16">
      <div *ngIf="previewModerationImageRequest" class="data">
        <div class="user-data">
          <div class="avatar"></div>
          <div class="user-name">
            <span>{{ previewModerationImageRequest.generatedImage.author.firstName }}</span>
            <span>{{ previewModerationImageRequest.generatedImage.author.lastName }}</span>
          </div>
        </div>

        <div class="divider"></div>

        <div class="prompt-data">
          <span class="header" i18n="@@admin.moderation.images.generation-data.prompt">
            admin.moderation.images.generation-data.prompt
          </span>
          <span class="prompt">{{ previewModerationImageRequest.generatedImage.generationSettings.prompt }}</span>
        </div>
      </div>
    </m-ng-island>

    <m-ng-island [paddingSize]="16" class="middle-island">
      <div *ngIf="previewModerationImageRequest" class="moderator-data">
        <form *ngIf="form" [formGroup]="form" class="moderator-description">
          <m-radio
            *ngFor="let o of descriptionOptions"
            class="radio-button"
            [value]="o.id"
            [isChecked]="o.id === selectedDescription?.id"
            (onSelect)="onSelectDescription(o)"
          >
            <span class="label">{{ o.text }}</span>
          </m-radio>

          <m-textarea
            class="textarea"
            [rows]="5"
            placeholder="admin.moderation.images.description-placeholder"
            i18n-placeholder="@@admin.moderation.images.description-placeholder"
            formControlName="moderatorDescription"
            type="normal"
            (focus)="onFocusModeratorDescription()"
          />
        </form>

        <m-button
          button-style="tertiary"
          icon="cross-02.svg"
          icon-position="left"
          i18n="@@admin.moderation.images.decline"
          color-theme="danger"
          (onClick)="onDeclineClick()"
          [is-disabled]="!isDeclineAvailable()"
        >
          admin.moderation.images.decline
        </m-button>
      </div>
    </m-ng-island>

    <m-ng-island [paddingSize]="16">
      <m-button
        *ngIf="previewModerationImageRequest"
        button-style="tertiary"
        icon="checkmark-02.svg"
        icon-position="left"
        i18n="@@admin.moderation.images.accept"
        (onClick)="onAcceptClick()"
      >
        admin.moderation.images.accept
      </m-button>
    </m-ng-island>
  </div>
</div>
