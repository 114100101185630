<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span i18n="@@user.profile.notifications.card.connect-telegram.title">
        user.profile.notifications.card.connect-telegram.title
      </span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div description class="description">
    <div class="row">
      <span i18n="@@user.profile.notifications.card.connect-telegram.description-1">
        user.profile.notifications.card.connect-telegram.description-1
      </span>
      <span
        class="action"
        i18n="@@user.profile.notifications.card.connect-telegram.description-action"
        (click)="showConnectTelegramModal()"
      >
        user.profile.notifications.card.connect-telegram.description-action
      </span>
      <span i18n="@@user.profile.notifications.card.connect-telegram.description-2">
        user.profile.notifications.card.connect-telegram.description-2
      </span>
    </div>
    <div class="row">
      <span i18n="@@user.profile.notifications.card.connect-telegram.description-3">
        user.profile.notifications.card.connect-telegram.description-3
      </span>
    </div>
  </div>
</m-account-notification-card-host>
