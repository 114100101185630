<m-ng-island [paddingSize]="24">
  <form *ngIf="!!promocode; else nopromocode" [formGroup]="form">
    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.token">admin.promocodes.promocode.token</span>
        <m-ng-input formControlName="token" />
      </div>

      <!-- <div class="control">
      <span class="label" i18n="@@admin.promocodes.promocode.status">admin.promocodes.promocode.status</span>
      <m-ng-input  formControlName="status" type="normal"/>
    </div> -->

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.agent">admin.promocodes.promocode.agent</span>
        <m-ng-input formControlName="agent" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.created-date">
          admin.promocodes.promocode.created-date
        </span>
        <m-ng-input formControlName="createdDate" type="date" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.expire-date">
          admin.promocodes.promocode.expire-date
        </span>
        <m-ng-input formControlName="expireDate" type="date" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.single-use">admin.promocodes.promocode.single-use</span>
        <m-switch formControlName="singleUse" input-style="on-panel" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.description">
          admin.promocodes.promocode.description
        </span>
        <m-ng-input formControlName="description" />
      </div>

      <!-- <div class="control">
      <span class="label" i18n="@@admin.promocodes.promocode.credits-count">
        admin.promocodes.promocode.credits-count
      </span>
      <m-ng-input-number  formControlName="creditsCount" />
    </div> -->

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.gold-credits-count">
          admin.promocodes.promocode.gold-credits-count
        </span>
        <m-ng-input-number formControlName="goldCreditsCount" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.tariff-id">admin.promocodes.promocode.tariff-id</span>
        <!-- <m-ng-input  formControlName="tariffId" -->
        <m-select
          class="select"
          input-style="normal"
          size="s"
          [options]="getOptionsForTariffsSelect()"
          formControlName="tariffId"
        ></m-select>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.subscription-period">
          admin.promocodes.promocode.subscription-period
        </span>
        <m-ng-input-number formControlName="subscriptionPeriod" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.discont-for-subscription">
          admin.promocodes.promocode.discont-for-subscription
        </span>
        <m-ng-input-number formControlName="discontForSubscription" />
      </div>
    </div>

    <div class="bottom-bar">
      <div class="buttons">
        <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" [is-disabled]="!form.valid" (onClick)="onSave()" i18n="@@admin.common.save">
          admin.common.save
        </m-button>
      </div>
    </div>
  </form>
  <ng-template #nopromocode>No promocode loaded</ng-template>
</m-ng-island>
