import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserBalance } from "@metranpage/user-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-credits-calculator-panel-buy-credits",
  templateUrl: "./calculator-panel-buy-credits.view.html",
  styleUrls: ["./calculator-panel-buy-credits.view.scss"],
})
export class CreditsCalculatorBuyCreditsView {
  @Input()
  creditPrice!: number;
  @Input()
  userBalance!: UserBalance;
  @Input()
  bookPriceCredits!: number;
  @Input()
  currency = "₽";

  @Output()
  onBuyCreditsClick = new EventEmitter<number>();

  constructor(private pricingViewService: PricingViewService) {}

  protected onBuyCreditsButtonClick(credits: number) {
    this.onBuyCreditsClick.emit(credits);
  }

  protected creditsNotEnough() {
    return this.pricingViewService.creditsNotEnough(this.userBalance.credits, this.bookPriceCredits);
  }

  protected getAdditionPrice() {
    return this.creditPrice * (this.bookPriceCredits - this.userBalance.credits);
  }

  protected priceFormat(value: number | undefined) {
    if (!value) {
      return;
    }
    return this.pricingViewService.priceFormat(value, this.currency);
  }

  protected pluralizeCredits(value: number) {
    return ` ${this.pricingViewService.pluralizeCredits(value)}`;
  }
}
