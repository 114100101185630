<div>
  <div class="row-price">
    <span>{{ bookPriceCredits }}{{ pluralizeCredits(bookPriceCredits) }}</span>
  </div>
  <div class="row-buy">
    <span class="indent-right" i18n="@@pricing.calculator.panel.not-enough">pricing.calculator.panel.not-enough</span>
    <span>{{ creditsNotEnough() }}{{ pluralizeCredits(creditsNotEnough()) }}</span>
    <m-button
      class="button-buy"
      button-style="primary"
      (onClick)="onBuyCreditsButtonClick(creditsNotEnough())"
      [is-disabled]="false"
    >
      <div class="button-text">
        <span class="indent-right" i18n="@@pricing.calculator.panel.buy-in-addition">
          pricing.calculator.panel.buy-in-addition
        </span>
        <span>{{ priceFormat(getAdditionPrice()) }}</span>
      </div>
    </m-button>
  </div>
</div>
