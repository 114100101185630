<m-pricing-info-panel [pricingData]="pricingData" [userBalance]="userBalance" [isLoading]="isCreditsInfoPanelLoading" />

<m-pricing-credits-calculator-panel
  [creditPrice]="creditPrice"
  [userBalance]="userBalance"
  [bookPriceCredits]="bookPrice"
  [bookData]="bookData"
  [isBookPresent]="isBookPresent"
  [creditsCalculatorResultData]="creditsCalculatorResultData"
  [isCreditsCalculatorLoading]="isCreditsCalculatorLoading"
  [isLoading]="isCalculatorPanelLoading"
  (onCalculateClick)="onCalculate($event)"
  (onBuyCreditsClick)="onCreditsBuy($event)"
  (onPayGoldCreditClick)="onPayGoldCredits($event)"
  (onLayoutBookClick)="onLayoutBook($event)"
  (onDropFiles)="dropped($event)"
/>

<m-pricing-credits-table
  [authorListSymbolsCount]="authorListSymbolsCount"
  [pricingData]="pricingData"
  [creditsCount]="customCreditsCount"
  [isLoading]="isCreditsTableLoading"
  (onBuyCreditsClick)="onCreditsBuy($event)"
></m-pricing-credits-table>
