<m-ng-island [paddingSize]="24">
  <div class="templates-group">
    <h1>Company templates</h1>

    <m-button button-style="primary" i18n="@@admin.templates.add-template" (onClick)="onAddTemplateClick()">
      admin.templates.add-template
    </m-button>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>Visibility to user</th>
          <th>title</th>
          <th *ngIf="canCreatePublicTemplates">Visibility to companies</th>
          <th></th>
        </thead>

        <tbody>
          <tr
            *ngFor="let template of templates; let i = index; trackBy: trackByTemplateId"
            (dragstart)="onDragStart($event, i)"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event, i)"
            draggable="true"
          >
            <td>{{template.id}}</td>

            <td>
              <m-icon *ngIf="template.isAvailableToUser" src="input-eye.svg" size="m" color="--color-success"></m-icon>
              <m-icon
                *ngIf="!template.isAvailableToUser"
                src="input-eye-closed.svg"
                size="m"
                color="--color-error"
              ></m-icon>
            </td>

            <td>{{template.title}}</td>

            <td *ngIf="canCreatePublicTemplates">
              <m-icon
                *ngIf="template.isVisibleToOtherCompanies"
                src="input-eye.svg"
                size="m"
                color="--color-success"
              ></m-icon>
              <m-icon
                *ngIf="!template.isVisibleToOtherCompanies"
                src="input-eye-closed.svg"
                size="m"
                color="--color-error"
              ></m-icon>
            </td>

            <td class="controls">
              <a [routerLink]="'/admin/templates/' + template.id">
                <m-icon class="settings-icon" size="s" src="gear.svg" color="--color-font-main" />
              </a>
              <a (click)="onCopyTemplateClick(template.id)">
                <m-icon class="settings-icon" size="s" src="copy.svg" color="--color-font-main" />
              </a>
              <a (click)="onDeleteTemplateClick(template.id)">
                <m-icon class="settings-icon" size="s" src="block-delete.svg" color="--color-error" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <m-admin-pagination
      [pageCount]="pageCount"
      [currentPage]="page"
      (onPageChanged)="onPageChanged($event)"
    ></m-admin-pagination>

    <m-modal *ngIf="isAddTemplateModalVisible" (close)="onCloseAddTemplateModal()">
      <m-admin-add-template (onTemplateAdded)="onTemplateAdded($event)"></m-admin-add-template>
    </m-modal>

    <m-modal *ngIf="isDeleteTemplateModalVisible" (close)="onCloseDeleteTemplateModal()">
      <div class="hide-modal">
        <p i18n="@@admin.templates.template.hide-template-confirmation">
          admin.templates.template.hide-template-confirmation
        </p>
        <m-button (onClick)="onDeleteTemplate()" button-style="secondary" size="full-width" i18n="@@admin.common.hide">
          admin.common.hide
        </m-button>
      </div>
    </m-modal>
  </div>

  <div class="templates-group">
    <h1>Public templates</h1>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>title</th>
          <th></th>
          <th></th>
          <th>Visibility to user</th>
        </thead>

        <tbody>
          <tr *ngFor="let templateAccess of templatesAccess; let i = index; trackBy: trackByTemplateId">
            <td>{{templateAccess.id}}</td>

            <td>{{templateAccess.template.title}}</td>

            <td>
              <m-icon
                *ngIf="templateAccess.isVisibleToUsers"
                src="input-eye.svg"
                size="m"
                color="--color-success"
              ></m-icon>
              <m-icon
                *ngIf="!templateAccess.isVisibleToUsers"
                src="input-eye-closed.svg"
                size="m"
                color="--color-error"
              ></m-icon>
            </td>

            <td>
              <div class="preview-images">
                <div *ngFor="let preview of templateAccess.template.previews" class="preview-image">
                  <img [src]="preview" alt="" />
                </div>
              </div>
            </td>

            <td>
              <m-switch
                [isChecked]="templateAccess.isVisibleToUsers"
                (onToggle)="onTemplateVisibleClick(templateAccess)"
                input-style="on-panel"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <m-admin-pagination
      [pageCount]="templatesAccessPageCount"
      [currentPage]="templatesAccessPage"
      (onPageChanged)="onTemplatesAccessPageChanged($event)"
    ></m-admin-pagination>
  </div>
</m-ng-island>
