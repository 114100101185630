import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  CheckboxForGroupComponent,
  CheckboxGroupComponent,
  ColorConverterComponent,
  ColorFormatSelectComponent,
  ColorPickerComponent,
  FilterSwitchComponent,
  FixedSidebar,
  FormErrorComponent,
  IconComponent,
  InfoBlockComponent,
  InputComponent,
  LangSelect2Component,
  LangSelectComponent,
  LoadingSpinnerComponent,
  LoadingSpinnerResizableComponent,
  LocalizedTextComponent,
  LogoComponent,
  ModalBackdropComponent,
  ModalComponent,
  NavTabsComponent,
  NewMarkComponent,
  PreviewDotsComponent,
  RadioComponent,
  RangeComponent,
  SelectComponent,
  SidePanelComponent,
  SidebarFixed,
  SidebarTabs,
  SkeletonImageComponent,
  SoonMarkComponent,
  SwitchBookTypeComponent,
  SwitchComponent,
  TextAreaComponent,
  TooltipComponent,
} from "@metranpage/components";
import { DesignSystemModule } from "@metranpage/design-system";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxFileDropModule } from "ngx-file-drop";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { Breadcrumbs } from "./breadcrumbs/breadcrumbs.view";
import { DebugLocaleDetectionComponent } from "./components/debug-locale-detection/debug-locale-detection.component";
import { FileDropComponent } from "./components/file-drop/file-drop.component";
import { NotificationHostComponent } from "./components/notification-host/notification-host.component";
import { NotificationComponent } from "./components/notification-pop-up/notification.component";
import { IsProdDirective } from "./directives/is-prod.directive";
import { AccountNotificationAddTokensCardView } from "./views/account-notification-cards/account-notification-add-tokens-card/account-notification-add-tokens-card.view";
import { AccountNotificationBaseCardView } from "./views/account-notification-cards/account-notification-base-card/account-notification-base-card.view";
import { AccountNotificationBookLayoutCardView } from "./views/account-notification-cards/account-notification-book-layout/account-notification-book-layout-card.view";
import { AccountNotificationBookWithoutCoverCardView } from "./views/account-notification-cards/account-notification-book-without-cover-card/account-notification-book-without-cover-card.view";
import { AccountNotificationCardHost } from "./views/account-notification-cards/account-notification-card-host/account-notification-card-host.view";
import { AccountNotificationConnectTelegramCardView } from "./views/account-notification-cards/account-notification-connect-telegram-card/account-notification-connect-telegram-card.view";
import { AccountNotificationCoverModerationCardView } from "./views/account-notification-cards/account-notification-cover-moderation-card/account-notification-cover-moderation-card.view";
import { AccountNotificationEmailVerificationCardView } from "./views/account-notification-cards/account-notification-email-verification-card/account-notification-email-verification-card.view";
import { AccountNotificationImageModerationCardView } from "./views/account-notification-cards/account-notification-image-moderation-card/account-notification-image-moderation-card.view";
import { AccountNotificationLowBalanceCardView } from "./views/account-notification-cards/account-notification-low-balance-card/account-notification-low-balance-card.view";
import { AccountNotificationPromocodeActivateCardView } from "./views/account-notification-cards/account-notification-promocode-activate-card/account-notification-promocode-activate-card.view";
import { AccountNotificationPromocodeExpireCardView } from "./views/account-notification-cards/account-notification-promocode-expire-card/account-notification-promocode-expire-card.view";
import { AccountNotificationRegistrationCardView } from "./views/account-notification-cards/account-notification-registration-card/account-notification-registration-card.view";
import { AccountNotificationSubscriptionActivateCardView } from "./views/account-notification-cards/account-notification-subscription-activate-card/account-notification-subscription-activate-card.view";
import { AccountNotificationSubscriptionExpireCardView } from "./views/account-notification-cards/account-notification-subscription-expire-card/account-notification-subscription-expire-card.view";
import { AccountNotificationTrialPeriodActivateCardView } from "./views/account-notification-cards/account-notification-trial-period-activate-card/account-notification-trial-period-activate-card.view";
import { AccountNotificationTrialPeriodExpireCardView } from "./views/account-notification-cards/account-notification-trial-period-expire-card/account-notification-trial-period-expire-card.view";
import { AccountNotificationsIconView } from "./views/account-notifications-icon/account-notifications-icon.view";
import { AccountNotificationsView } from "./views/account-notifications/account-notifications.view";
import { AccountRewardBaseCardView } from "./views/account-reward-base-card/account-reward-base-card.view";
import { AccountRewardTasksListView } from "./views/account-reward-tasks-list/account-reward-tasks-list.view";
import { RewardTaskConnectTelegramView } from "./views/account-reward-tasks-list/tasks/reward-task-connect-telegram/reward-task-connect-telegram.view";
import { AccountRewardsIconView } from "./views/account-rewards-icon/account-rewards-icon.view";
import { AccountRewardsView } from "./views/account-rewards/account-rewards.view";

@NgModule({
  declarations: [
    FileDropComponent,
    IsProdDirective,
    DebugLocaleDetectionComponent,
    AccountNotificationsView,
    AccountNotificationCardHost,
    AccountNotificationBaseCardView,
    AccountNotificationImageModerationCardView,
    AccountNotificationCoverModerationCardView,
    AccountNotificationPromocodeActivateCardView,
    AccountNotificationPromocodeExpireCardView,
    AccountNotificationEmailVerificationCardView,
    AccountNotificationTrialPeriodActivateCardView,
    AccountNotificationTrialPeriodExpireCardView,
    AccountNotificationBookLayoutCardView,
    AccountNotificationBookWithoutCoverCardView,
    AccountNotificationSubscriptionActivateCardView,
    AccountNotificationSubscriptionExpireCardView,
    AccountNotificationLowBalanceCardView,
    AccountNotificationRegistrationCardView,
    AccountNotificationsIconView,
    AccountNotificationConnectTelegramCardView,
    AccountNotificationAddTokensCardView,
    AccountRewardsIconView,
    AccountRewardsView,
    AccountRewardTasksListView,
    AccountRewardBaseCardView,
    RewardTaskConnectTelegramView,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    RouterModule,
    CardComponent,
    LogoComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerResizableComponent,
    SwitchComponent,
    SwitchBookTypeComponent,
    ModalBackdropComponent,
    ModalComponent,
    IconComponent,
    LocalizedTextComponent,
    LangSelectComponent,
    LangSelect2Component,
    FormErrorComponent,
    PreviewDotsComponent,
    SidePanelComponent,
    ColorPickerComponent,
    ColorConverterComponent,
    ColorFormatSelectComponent,
    RangeComponent,
    RadioComponent,
    FixedSidebar,
    SidebarFixed,
    SidebarTabs,
    TooltipComponent,
    SkeletonImageComponent,
    // OnboardingModule,
    NavTabsComponent,
    InfoBlockComponent,
    Breadcrumbs,
    CheckboxGroupComponent,
    CheckboxForGroupComponent,
    FilterSwitchComponent,
    NewMarkComponent,
    SoonMarkComponent,

    DesignSystemModule,
    InfiniteScrollModule,

    NotificationHostComponent,
    NotificationComponent,
  ],
  exports: [
    BrowserAnimationsModule,
    FileDropComponent,
    CheckboxComponent,
    IsProdDirective,
    AngularSvgIconModule,
    ReactiveFormsModule,
    CommonModule,
    CardComponent,
    LogoComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerResizableComponent,
    SwitchComponent,
    SwitchBookTypeComponent,
    IconComponent,
    ModalBackdropComponent,
    ModalComponent,
    DebugLocaleDetectionComponent,
    LocalizedTextComponent,
    LangSelectComponent,
    LangSelect2Component,
    FormErrorComponent,
    PreviewDotsComponent,
    SidePanelComponent,
    ColorPickerComponent,
    ColorConverterComponent,
    ColorFormatSelectComponent,
    RangeComponent,
    RadioComponent,
    FormErrorComponent,
    FixedSidebar,
    SidebarFixed,
    SidebarTabs,
    TooltipComponent,
    SkeletonImageComponent,
    // OnboardingModule,
    NavTabsComponent,
    InfoBlockComponent,
    Breadcrumbs,
    CheckboxGroupComponent,
    CheckboxForGroupComponent,
    FilterSwitchComponent,
    NewMarkComponent,
    SoonMarkComponent,

    DesignSystemModule,
    InfiniteScrollModule,

    NotificationHostComponent,
    NotificationComponent,
    AccountNotificationsView,
    AccountNotificationBaseCardView,
    AccountNotificationImageModerationCardView,
    AccountNotificationPromocodeActivateCardView,
    AccountNotificationPromocodeExpireCardView,
    AccountNotificationEmailVerificationCardView,
    AccountNotificationTrialPeriodActivateCardView,
    AccountNotificationTrialPeriodExpireCardView,
    AccountNotificationBookLayoutCardView,
    AccountNotificationBookWithoutCoverCardView,
    AccountNotificationSubscriptionActivateCardView,
    AccountNotificationSubscriptionExpireCardView,
    AccountNotificationLowBalanceCardView,
    AccountNotificationRegistrationCardView,
    AccountNotificationsIconView,
    AccountNotificationConnectTelegramCardView,
    AccountNotificationAddTokensCardView,
    AccountRewardsIconView,
    AccountRewardsView,
    AccountRewardTasksListView,
    AccountRewardBaseCardView,
    RewardTaskConnectTelegramView,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  bootstrap: [],
})
export class CoreModule {}
