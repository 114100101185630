import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { User } from "@metranpage/user-data";

@Component({
  selector: "m-account-connect-telegram-modal-view",
  templateUrl: "./account-connect-telegram-modal.view.html",
  styleUrls: ["./account-connect-telegram-modal.view.scss"],
})
export class AccountConnectTelegramModalView {
  @Input()
  user?: User;

  @Output()
  onClose = new EventEmitter<void>();
  @Output()
  onConnect = new EventEmitter<void>();
  @Output()
  onSubscribeToChannel = new EventEmitter<void>();
  @Output()
  onCheckSubscribeToChannel = new EventEmitter<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.detectChanges();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  protected onConnectClick() {
    this.onConnect.emit();
  }

  protected onSubscribeToChannelClick() {
    this.onSubscribeToChannel.emit();
  }

  protected onCheckSubscribeToChannelClick() {
    this.onCheckSubscribeToChannel.emit();
  }

  protected canCheckSubscriptionToChannel() {
    return this.user?.telegramProfile;
  }
}
