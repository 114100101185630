import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";

@Component({
  selector: "m-language-select-view",
  templateUrl: "./language-select.view.html",
  styleUrls: ["./language-select.view.scss"],
})
export class LanguageSelectView {
  @Input()
  userLanguage = "ru";

  @Output()
  onLangChangeClick = new EventEmitter<string>();

  constructor(@Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[]) {}

  protected isLanguageSwitchAvailable() {
    return this.availableLanguages.length > 1;
  }
}
