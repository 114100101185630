import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { FileDropComponent } from "@metranpage/core";
import { CalculatorBookData, CalculatorResult } from "@metranpage/pricing-data";
import { UserBalance } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-credits-calculator-panel",
  templateUrl: "./calculator-panel.view.html",
  styleUrls: ["./calculator-panel.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CreditsCalculatorPanelView {
  @Input()
  creditPrice!: number;
  @Input()
  userBalance!: UserBalance;
  @Input()
  bookPriceCredits!: number;
  @Input()
  bookData?: CalculatorBookData;
  @Input()
  creditsCalculatorResultData?: CalculatorResult;
  @Input()
  isFileDropShow = true;
  @Input()
  isCreditsCalculatorLoading = false;
  @Input()
  isCalculationShow = false;
  @Input()
  isBookPresent = false;
  @Input()
  isLoading = false;
  @Input()
  currency = "₽";

  @Output()
  onCalculateClick = new EventEmitter<CalculatorBookData>();
  @Output()
  onBuyCreditsClick = new EventEmitter<number>();
  @Output()
  onPayGoldCreditClick = new EventEmitter<number>();
  @Output()
  onLayoutBookClick = new EventEmitter<number>();
  @Output()
  onDropFiles = new EventEmitter<NgxFileDropEntry[]>();

  @ViewChild("filedrop", { static: false })
  public fileDropComponent!: FileDropComponent;

  book: Book | undefined;
  // isErrorModalVisible = false;
  errorText = "";

  constructor(private pricingViewService: PricingViewService) {}

  ngOnInit(): void {}

  dropped(files: NgxFileDropEntry[]) {
    this.onDropFiles.emit(files);
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  // closeErrorModal() {
  //   this.isErrorModalVisible = false;
  // }

  async onCalculate(data: CalculatorBookData) {
    this.onCalculateClick.emit(data);
  }

  protected onBuyCreditsButtonClick(credits: number) {
    this.onBuyCreditsClick.emit(credits);
  }

  protected onPayGoldCreditsButtonClick(goldCredits: number) {
    this.onPayGoldCreditClick.emit(goldCredits);
  }

  protected onLayoutBookButtonClick(credits: number) {
    this.onLayoutBookClick.emit(credits);
  }

  protected isCreditsNotEnough() {
    return this.bookPriceCredits > this.userBalance.credits;
  }

  protected creditsNotEnough() {
    return this.pricingViewService.creditsNotEnough(this.userBalance.credits, this.bookPriceCredits);
  }

  protected getAdditionPrice() {
    return this.creditPrice * (this.bookPriceCredits - this.userBalance.credits);
  }

  protected hasGoldCredits() {
    return this.userBalance.goldCredits > 0;
  }

  protected onShowCalculationButtonClick() {
    this.isCalculationShow = true;
  }

  protected onCloseCalculationButtonClick() {
    this.isCalculationShow = false;
  }

  protected profitPricePercent() {
    return this.creditsCalculatorResultData?.profit?.price || 0;
  }

  protected profitTimePercent() {
    return this.creditsCalculatorResultData?.profit?.time || 0;
  }

  protected priceFormat(value: number | undefined) {
    if (!value) {
      return;
    }
    return this.pricingViewService.priceFormat(value, this.currency);
  }

  protected pluralizeCredits(value: number) {
    return ` ${this.pricingViewService.pluralizeCredits(value)}`;
  }

  protected pluralizeCreditsNeed(value: number) {
    return this.pricingViewService.pluralizeCreditsNeed(value);
  }
}
