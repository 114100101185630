<m-ng-island [paddingSize]="24">
  <!-- <m-localized-text text="admin.cloudpayments.description" i18n-text="@@admin.cloudpayments.description"/> -->
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <span i18n="@@admin.unisender.unisender-api-key">admin.unisender.unisender-api-key</span>
      <m-ng-input formControlName="unisenderApiKey" />
    </div>
    <div>
      <span i18n="@@admin.unisender.unisender-go-api-key">admin.unisender.unisender-go-api-key</span>
      <m-ng-input formControlName="unisenderGoApiKey" />
    </div>
  </form>

  <div class="row">
    <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
      admin.common.save
    </m-button>

    <m-button button-style="secondary" (onClick)="onDelete()" i18n="@@admin.common.delete">
      admin.common.delete
    </m-button>
  </div>
</m-ng-island>
