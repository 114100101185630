import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyTelegramSettings } from "../../models/company";

export type State = {
  settings: CompanyTelegramSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyTelegramSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      settings: undefined,
    };
  }

  getSettingsObservable() {
    return this.getFieldObservable("settings");
  }

  setSettings(settings: CompanyTelegramSettings) {
    this.update((state) => ({ ...state, settings }));
  }
}
