import { Component, Input } from "@angular/core";
import { User } from "@metranpage/user-data";
import { ConnectTelegramService } from "../../services/connect-telegram.service";

@Component({
  selector: "m-account-reward-tasks-list-view",
  templateUrl: "account-reward-tasks-list.view.html",
  styleUrls: ["account-reward-tasks-list.view.scss"],
})
export class AccountRewardTasksListView {
  @Input()
  user?: User;

  constructor(private readonly connectTelegramService: ConnectTelegramService) {}

  protected isTelegramConnectionAvailable() {
    return this.connectTelegramService.isTelegramConnectionAvailable();
  }
}
