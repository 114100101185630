import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { I18nService } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { Company, CompanyDto, CompanyUpdateData } from "../../models/company";
import { AdminCompanyApi } from "./company.api";
import { AdminCompanyStore } from "./company.store";

export type UpdateCompanyResult = {
  status: GeneralResultStatus;
  error?: unknown;
  company?: Company;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyService {
  constructor(
    private readonly adminCompanyApi: AdminCompanyApi,
    private readonly adminCompanyStore: AdminCompanyStore,
    private readonly i18nService: I18nService,
  ) {}

  async refreshCompany() {
    const companyData = await this.adminCompanyApi.getCompany();
    const company = this.updateCompanyLocalization(companyData);
    this.adminCompanyStore.setCompany(company);
  }

  async updateCompany(data: CompanyUpdateData): Promise<UpdateCompanyResult> {
    try {
      const companyData = await this.adminCompanyApi.updateCompany(data);
      const company = this.updateCompanyLocalization(companyData);
      this.adminCompanyStore.setCompany(company);
      return {
        status: "success",
        company: company,
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  updateCompanyLocalization(data: CompanyDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              lang: "",
              companyId: -1,
            };
    }

    const companyLocalizedData = _.omit(localeData, ["id", "lang", "companyId"]);
    const company: Company = {
      ...data,
      ...companyLocalizedData,
    };
    return company;
  }
}
