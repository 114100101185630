<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p><b i18n="@@admin.companies.edit-company">admin.companies.edit-company</b></p>
  <div class="container">
    <!-- <div>
      <span i18n="@@admin.companies.company.title">admin.companies.company.title</span>
      <m-ng-input formControlName="title" />
    </div> -->
    <div formGroupName="localization">
      <span i18n="@@admin.format.add-format.title">admin.format.add-format.title</span>

      <div class="localizations" *ngFor="let language of getLanguages()" [formGroupName]="language">
        <p class="section-title">{{ language.toUpperCase() }}</p>

        <div class="control">
          <m-ng-input formControlName="title" />
        </div>
      </div>
    </div>

    <div>
      <span i18n="@@admin.companies.company.landing-url">admin.companies.company.landing-url</span>
      <m-ng-input formControlName="landingUrl" />
    </div>

    <div>
      <span i18n="@@admin.companies.company.host-url">admin.companies.company.host-url</span>
      <m-ng-input formControlName="hostUrl" />
    </div>

    <div>
      <span i18n="@@admin.companies.company.knowledge-base-url">admin.companies.company.knowledge-base-url</span>
      <m-ng-input formControlName="knowledgeBaseUrl" />
    </div>

    <div>
      <span i18n="@@admin.companies.company.receipt-template-for-subscriptions">
        admin.companies.company.receipt-template-for-subscriptions
      </span>
      <m-ng-input formControlName="receiptTemplateForSubscription" />
    </div>

    <div>
      <span i18n="@@admin.companies.company.can-produce-epub">admin.companies.company.can-produce-epub</span>
      <m-switch formControlName="canProduceEpub" />
    </div>

    <div>
      <span i18n="@@admin.companies.company.is-logout-button-available">
        admin.companies.company.is-logout-button-available
      </span>
      <m-switch formControlName="isLogoutButtonAvailable" />
    </div>

    <p class="templates-header" i18n="@@admin.companies.company.unisender-templates">
      admin.companies.company.unisender-templates
    </p>
    <div class="templates" formGroupName="unisenderSettings">
      <div class="template" *ngFor="let template of getUnisenderListKey()" [formGroupName]="template">
        <p class="section-title">{{ template }}</p>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-template-settings.list-id">
            admin.companies.company.unisender-template-settings.list-id
          </span>
          <m-ng-input-number formControlName="listId" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-template-settings.timeout">
            admin.companies.company.unisender-template-settings.timeout
          </span>
          <m-ng-input-number formControlName="timeout" />
        </div>
      </div>
    </div>

    <p class="templates-header" i18n="@@admin.companies.company.unisender-go-templates">
      admin.companies.company.unisender-go-templates
    </p>
    <div class="templates" formGroupName="unisenderGoSettings">
      <div class="template" *ngFor="let language of getUnisenderTemplateLanguages()" [formGroupName]="language">
        <p class="section-title">{{ language.toUpperCase() }}</p>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.verification-email">
            admin.companies.company.unisender-go-template-id.verification-email
          </span>
          <m-ng-input formControlName="templateIdVerificationEmail" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.restore-password">
            admin.companies.company.unisender-go-template-id.restore-password
          </span>
          <m-ng-input formControlName="templateIdRestorePassword" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.subscribtion-ending-notice">
            admin.companies.company.unisender-go-template-id.subscribtion-ending-notice
          </span>
          <m-ng-input formControlName="templateIdSubscribtionEndingNotice" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.fail-payment-notice">
            admin.companies.company.unisender-go-template-id.fail-payment-notice
          </span>
          <m-ng-input formControlName="templateIdFailPaymentNotice" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.force-free-tariff-notice">
            admin.companies.company.unisender-go-template-id.force-free-tariff-notice
          </span>
          <m-ng-input formControlName="templateIdForceFreeTariffNotice" />
        </div>

        <div class="control">
          <span i18n="@@admin.companies.company.unisender-go-template-id.trial-end-notice">
            admin.companies.company.unisender-go-template-id.trial-end-notice
          </span>
          <m-ng-input formControlName="templateIdTrialEndNotice" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
      admin.common.save
    </m-button>
    <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
      admin.common.cancel
    </m-button>
  </div>
</form>
