<m-modal position="center" [closeButtonVisible]="true" [paddingSize]="24" (close)="onCloseClick()">
  <div class="container">
    <img src="assets/img/telegram-connect-modal.png" />

    <m-localized-text
      class="title"
      text="user.profile.socials.telegram.modal.title"
      i18n-text="@@user.profile.socials.telegram.modal.title"
    />

    <div class="group">
      <span class="group-title" i18n="@@user.profile.socials.telegram.modal.conditions-title">
        user.profile.socials.telegram.modal.conditions-title
      </span>

      <m-button
        button-style="secondary"
        size="full-width"
        icon-position="left"
        icon="button-open-link.svg"
        text-align="left"
        i18n="@@user.profile.socials.telegram.link-account"
        (onClick)="onConnectClick()"
      >
        user.profile.socials.telegram.link-account
      </m-button>

      <m-button
        button-style="secondary"
        size="full-width"
        icon-position="left"
        icon="button-open-link.svg"
        text-align="left"
        i18n="@@user.profile.socials.telegram.subscription-to-channel"
        (onClick)="onSubscribeToChannelClick()"
      >
        user.profile.socials.telegram.subscription-to-channel
      </m-button>
    </div>

    <div class="group">
      <span class="group-title" i18n="@@user.profile.socials.telegram.modal.check-title">
        user.profile.socials.telegram.modal.check-title
      </span>

      <m-button
        button-style="primary"
        size="full-width"
        icon-position="left"
        icon="button-circle-arrows.svg"
        text-align="center"
        i18n="@@user.profile.socials.telegram.modal.check-subscription-to-channel"
        [is-disabled]="!canCheckSubscriptionToChannel()"
        (onClick)="onCheckSubscribeToChannelClick()"
      >
        user.profile.socials.telegram.modal.check-subscription-to-channel
      </m-button>
    </div>
  </div>
</m-modal>
