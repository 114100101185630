import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectComponent } from "@metranpage/components";

export type SelectValue = {
  value: string;
  id: number | string;
};

export type InputStyle = "normal" | "on-panel";
export type InputSize = "s" | "m" | "m-plus";

@Component({
  selector: "m-select-agelimit",
  templateUrl: "./select-age-limit.component.html",
  styleUrls: ["./select-age-limit.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponentAgeLimit),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponentAgeLimit extends SelectComponent {
  protected showAgeRestrictionModal = false;

  @Output() onAgeRestrictionModalOpen = new EventEmitter<void>();

  constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _cdr: ChangeDetectorRef,
  ) {
    super(_elementRef, _cdr);
  }

  protected onShowAgeRestrictionModalClick() {
    this.onAgeRestrictionModalOpen.emit();
  }
}
