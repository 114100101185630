import { Injectable } from "@angular/core";
import { SelectValue } from "@metranpage/components";
import { GeneralResultStatus } from "@metranpage/core-data";
import { I18nService } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { CompaniesFilter, Company, CompanyCreateData, CompanyDto } from "../models/company";
import { CompaniesAdminStore } from "./companies.store";
import { SuperadminApi } from "./superadmin.api";

@Injectable({
  providedIn: "root",
})
export class CompaniesAdminService {
  constructor(
    private readonly api: SuperadminApi,
    private readonly store: CompaniesAdminStore,
    private readonly i18nService: I18nService,
  ) {}

  async loadCompaniesPaginated(page: number, filters: CompaniesFilter = {}) {
    const companiesData = await this.api.getCompaniesPaginated(page, filters);
    const companies = companiesData.items.map((c) => this.updateCompanyLocalization(c));
    this.store.setCompanies(companies);
    this.store.setCompaniesPageCount(companiesData.pageCount);
  }

  async loadCompanyAndSetActive(id: number) {
    const company = await this.api.getCompany(id);
    this.store.setActiveCompany(company);
  }

  async updateCompanyData(company: Company, value: { title: string }) {
    await this.api.updateCompanyData(company.id, value);
  }

  async createCompany(data: CompanyCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newCompany = await this.api.createCompany(data);
      return {
        status: "success",
        id: newCompany.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteCompany(companyId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.api.deleteCompany(companyId);
      return {
        status: "success",
        //   id: newCompany.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        //   id: undefined,
      };
    }
  }

  getOptionsForCompaniesSelect(companies: Company[], addAllSelector = true) {
    const options: SelectValue[] = [];
    if (addAllSelector) {
      options.push({ id: 0, value: $localize`:@@admin.common.select.all:` });
    }
    for (const company of companies) {
      options.push({
        id: company.id,
        value: company.title,
      });
    }
    return _.sortBy(options, ["id"]);
  }

  async getCompanyAdmins(companyId: number) {
    try {
      const result = await this.api.getCompanyAdmins(companyId);
      return {
        status: "success",
        data: result,
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }
  async addCompanyAdmin(id: number, email: string): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.addCompanyAdmin(id, { email });
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  updateCompanyLocalization(data: CompanyDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              lang: "",
              companyId: -1,
            };
    }

    const companyLocalizedData = _.omit(localeData, ["id", "lang", "companyId"]);
    const company: Company = {
      ...data,
      ...companyLocalizedData,
    };
    return company;
  }
}
