import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { timer } from "rxjs";
import { ConnectTelegramService } from "../../../services/connect-telegram.service";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-connect-telegram-card-view",
  templateUrl: "account-notification-connect-telegram-card.view.html",
  styleUrls: ["account-notification-connect-telegram-card.view.scss"],
})
export class AccountNotificationConnectTelegramCardView extends AccountNotificationBaseCardView<undefined> {
  constructor(
    private readonly router: Router,
    private readonly connectTelegramService: ConnectTelegramService,
  ) {
    super();
  }

  protected showConnectTelegramModal() {
    this.router.navigate(["/account/rewards"]);
    timer(1000).subscribe(() => {
      this.connectTelegramService.showConnectTelegramModal();
    });
  }
}
