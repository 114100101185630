<div class="card" [class.unread]="!reward.isShown">
  <div class="wrapper">
    <div class="icon">
      <m-ng-icon class="icon-main" [size]="20" [src]="iconSrc" />
    </div>

    <div class="content">
      <div class="title">{{ title }}</div>
      <div class="date">{{ date }}</div>
    </div>
  </div>

  <div class="tokens-group">
    <div class="tokens">+{{ reward.creditsCount }}</div>
    <m-ng-icon [size]="24" src="reward-tokens.svg" />
  </div>
</div>

<div *ngIf="!isLast" class="divider-hor"></div>
