import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyDto, CompanyUpdateData } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getCompany() {
    return this.get<CompanyDto>("admin/company");
  }

  async updateCompany(data: CompanyUpdateData) {
    return this.put<CompanyDto>("admin/company", data);
  }
}
