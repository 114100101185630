<m-ng-island [paddingSize]="24">
  <form *ngIf="!!nonEditableTemplate; else noTemplate" [formGroup]="form">
    <div class="control">
      <span class="label" i18n="@@admin.templates.template.title">admin.templates.template.title</span>
      <m-ng-input formControlName="title" />
    </div>

    <div class="divider"></div>

    <div class="archive">
      <m-file-drop
        *ngIf="!archiveFile && !originalUploadFilename"
        #filedrop
        [multiple]="false"
        (dropped)="dropped($event)"
        style="--custom-height: 256px"
        accept=".zip"
      >
        <div class="drop-placeholder">
          <div>
            <p class="description-text">
              <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
            </p>
            <p class="description-text">
              &nbsp;
              <span
                class="mark"
                (click)="openFileSelector()"
                [style.color]="'#e02379'"
                i18n="@@books.upload.drop-text2-upload"
              >
                books.upload.drop_text2_upload
              </span>
            </p>
          </div>
        </div>
      </m-file-drop>

      <div *ngIf="originalUploadFilename" class="archive-file">
        <b>{{originalUploadFilename}}</b>

        <m-icon
          src="book-delete.svg"
          size="m"
          color="--color-error"
          class="delete"
          (click)="deleteArchiveFile()"
        ></m-icon>
      </div>
    </div>

    <div class="end-spacer"></div>

    <div class="bottom-bar">
      <div class="buttons">
        <m-button button-style="secondary" (onClick)="onBackClick()" i18n="@@admin.common.cancel">
          admin.common.cancel
        </m-button>
        <m-button
          button-style="primary"
          (onClick)="onSaveNonEditableTemplate()"
          [is-disabled]="!form.valid || !this.originalUploadFilename"
          i18n="@@admin.common.save"
        >
          admin.common.save
        </m-button>
      </div>
    </div>
  </form>
  <ng-template #noTemplate>No template loaded</ng-template>
</m-ng-island>
