import { Component, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Component({
  selector: "m-markup-editor-text-generation-context-menu",
  templateUrl: "./text-generation-context-menu.view.html",
  styleUrls: ["./text-generation-context-menu.view.scss"],
})
export class TextGenerationContextMenuView {
  @Output()
  onClick = new EventEmitter<string>();
  @Output()
  onClose = new EventEmitter<void>();

  constructor(private readonly elementRef: ElementRef) {}

  close() {
    this.onClose.emit();
  }

  click(action: string) {
    this.onClick.emit(action);
  }

  @HostListener("document:click", ["$event"])
  protected onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.close();
  }
}
