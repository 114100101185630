import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'm-create-book-card-view',
  template: `
    <img class="cover" [src]="image" [alt]="name" />
    <span>
      {{ name }}
    </span>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        padding: 16px;

        cursor: default;

        span {
          color: var(--color-font-not-active);
        }

        &.available {
          cursor: pointer;

          span {
            color: var(--color-font-main);
          }
        }

        &:hover {
          border-radius: var(--border-radius-l);

          background-color: var(--color-bg-panel);
        }
      }

      .cover {
        width: 245px;
        height: 370px;
      }
    `,
  ],
})
export class SelectBookTypeCardView {
  @Input()
  image!: string;
  @Input()
  name!: string;
  @HostBinding('class.available')
  @Input()
  isAvailable!: boolean;
}
