<m-ng-island [paddingSize]="24">
  <div>
    <p>
      <span i18n="@@admin.api.available-token">admin.api.available-token</span>
      &nbsp;
      <span>
        <b>{{(apiSettings$ | async)?.token}}</b>
      </span>
    </p>
  </div>

  <m-button button-style="primary" (onClick)="onResetToken()" i18n="@@admin.api.reset-token">
    admin.api.reset-token
  </m-button>

  <hr />

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <span i18n="@@admin.api.webhook-url">admin.api.webhook-url</span>
      <m-ng-input formControlName="webhookUrl" />
    </div>

    <div *ngIf="lastWebhookResult">
      <span i18n="@@admin.api.webhook-result">admin.api.webhook-result</span>
      <pre>{{lastWebhookResult.requestUrl}}</pre>
      <pre>{{lastWebhookResult.responseCode}}</pre>
      <pre>{{lastWebhookResult.response}}</pre>
      <pre>{{lastWebhookResult.error}}</pre>
    </div>

    <m-button button-style="secondary" (onClick)="testWebhook()" i18n="@@admin.api.test-webhook">
      admin.api.test-webhook
    </m-button>

    <div>
      <span i18n="@@admin.api.redirect-on-layout-finish-url">admin.api.redirect-on-layout-finish-url</span>
      <m-ng-input formControlName="redirectOnLayoutFinishUrl" />
    </div>
  </form>

  <div class="row">
    <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
      admin.common.save
    </m-button>
  </div>

  <hr />
  <p>
    <span i18n="@@admin.api.link-to-doc">admin.api.link-to-doc</span>
    <a class="link" href="https://docs.metranpage.com/integrations-openapi" target="_blank">integrations-openapi</a>
  </p>
</m-ng-island>
