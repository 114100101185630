import { Component, ElementRef, Input, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'm-markup-font-size-input',
  template: `
    <input class="size" [class.error]="hasError" value="10" type="text" #input (input)="onInputChange($event)" />
    <div class="arrows" *ngIf="showControls">
      <m-icon src="dropdown-arrow-down.svg" size="xs" class="up" (click)="onArrowClick(+1)" />
      <m-icon src="dropdown-arrow-down.svg" size="xs" class="down" (click)="onArrowClick(-1)" />
    </div>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }

      .size {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        background-color: var(--color-bg-input);
        font-size: 14px;
        background-image: none;
        filter: none;
        outline: none;
        margin: 0;
        appearance: none;
        text-align: center;

        &.error {
          border: 1px solid var(--color-error);
        }
      }

      .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        m-icon {
          width: 8px;
          height: 8px;
        }

        .up {
          transform: rotate(180deg);
        }

        .down {
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupFontSizeInput),
      multi: true,
    },
  ],
})
export class MarkupFontSizeInput {
  @Input()
  showControls!: boolean;
  @Input()
  hasError = false;

  protected value = 0;

  @ViewChild('input', { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private renderer: Renderer2) {}

  writeValue(value: number): void {
    this.value = value;
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', this.value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onInputChange(event: Event) {
    const value = (event.target! as HTMLInputElement).value;
    this.value = +value; // cast to number
    this.onChange(this.value);
  }

  onArrowClick(toAdd: number) {
    this.value = this.value + toAdd;
    this.writeValue(this.value);
    this.onChange(this.value);
  }
}
