import { Injectable } from "@angular/core";
import { FeatureToggleAdminStore } from "./feature-toggle.store";
import { SuperadminApi } from "./superadmin.api";

@Injectable({
  providedIn: "root",
})
export class FeatureToggleAdminService {
  constructor(
    private readonly api: SuperadminApi,
    private readonly store: FeatureToggleAdminStore,
  ) {}

  async loadFeatureToggles() {
    const toggles = await this.api.getFeatureToggles();
    this.store.setFeatureToggles(toggles);
  }

  async setFeatureToggle(title: string, isEnabled: boolean) {
    await this.api.setFeatureToggle(title, isEnabled);
    await this.loadFeatureToggles();
  }
}
