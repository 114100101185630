import { Component, Input } from "@angular/core";
import { InputFieldError } from "@metranpage/pricing-data";

@Component({
  selector: "m-input-field-error",
  template: `
    <svg-icon src="/assets/icons/login-error.svg" class="le-icon"></svg-icon>
    <span class="le-label">{{ getTextFromError() }}</span>
  `,
  styles: [
    `
      @import '../../../../../apps/metranpage/src/styles/mixins';

      :host {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
      }

      .le-label {
        margin-left: 6px;
        color: var(--color-error-text);
        font-size: 14px;
      }

      .le-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    `,
  ],
})
export class InputFieldErrorComponent {
  @Input()
  error?: InputFieldError;

  getTextFromError() {
    if (!this.error) {
      return "";
    }
    switch (this.error) {
      case "empty-field":
        return $localize`:@@pricing.error.empty-field:`;
      case "not-digits":
        return $localize`:@@pricing.error.should-be-digits:`;
      case "eq-zero":
        return $localize`:@@pricing.error.should-be-gt-zero:`;
      case "lt-zero":
        return $localize`:@@pricing.error.should-be-eq-gt-zero:`;
      default:
        return "";
    }
  }
}
