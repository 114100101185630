<m-ng-island [paddingSize]="24">
  <div *ngIf="!!referalLink; else noreferalLink" class="container">
    <div class="info">
      <div>ID: {{referalLink.id}}</div>
      <div>TOKEN: {{referalLink.token}}</div>
      <div>Created date: {{getDate(referalLink.createdAt)}}</div>
    </div>

    <div class="stats">
      <table>
        <thead>
          <th>Period</th>
          <th>Registered Count</th>
          <th>Orders Count</th>
          <th>Orders Amount</th>
          <th>Agent Profit</th>
        </thead>

        <tbody>
          <tr *ngFor="let stat of stats">
            <td>{{ getDateShort(stat.period) }}</td>
            <td>{{ stat.usersRegisteredCount }}</td>
            <td>{{ stat.ordersCount }}</td>
            <td>{{ stat.ordersAmount }}</td>
            <td>{{ stat.agentProfit }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="bottom-bar">
      <div class="buttons">
        <m-button button-style="secondary" (onClick)="onBack()" i18n="@@dialog.back-button">
          dialog.back-button
        </m-button>
      </div>
    </div>
  </div>
  <ng-template #noreferalLink>No referalLink loaded</ng-template>
</m-ng-island>
