<div class="container">
  <ng-container *ngIf="!preview">
    <m-icon src="/assets/images/template-from-scratch-{theme}.svg" [class.active]="isDetailsAvailable"
      (click)="onPreview.emit()" alt="" class="preview" size="fit" />
  </ng-container>

  <ng-container *ngIf="preview">
    <m-skeleton-image [url]="preview" (click)="onPreview.emit()" [class.active]="isDetailsAvailable" width="378"
      height="260"></m-skeleton-image>
  </ng-container>

  <m-template-preview-tag *ngIf="tagSettings" [settings]="tagSettings" />

  <div class="info">
    <p class="title">{{ title }}</p>
    <p class="description">{{ description }}⠀</p>
  </div>
</div>

<div *ngIf="tagSettings?.type !== 'blocked'" class="buttons">
  <div *ngIf="isDetailsAvailable" class="more">
    <m-button i18n="@@books.template.select-template.details-button" button-style="tertiary" size="fill"
      (onClick)="onPreview.emit()">
      books.template.select-template.details-button
    </m-button>
  </div>
  <div class="select">
    <m-button i18n="@@books.template.select-template.select-button" button-style="tertiary" size="fill" icon="done.svg"
      icon-position="left" (onClick)="onSelect.emit()">
      books.template.select-template.select-button
    </m-button>
  </div>
</div>

<div *ngIf="tagSettings?.type === 'blocked'" class="buttons">
  <div class="blocked-info">
    <m-icon src="info-2.svg" color="--warning" size="xs-16" />
    <m-localized-text class="name" [text]="tagSettings?.info" />
  </div>
</div>
