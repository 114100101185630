<m-ng-island [paddingSize]="24">
  <div class="buttons">
    <m-button
      button-style="primary"
      i18n="@@admin.non-editable-templates.non-editable-template.add-non-editable-template"
      (onClick)="onAddNonEditableTemplateClick()"
    >
      admin.non-editable-templates.non-editable-template.add-non-editable-template
    </m-button>

    <m-button
      button-style="primary"
      i18n="@@admin.non-editable-templates.non-editable-template.download-non-editable-template-example"
      (onClick)="onGetNonEditableTemplateExampleClick()"
    >
      admin.non-editable-templates.non-editable-template.download-non-editable-template-example
    </m-button>
  </div>

  <form [formGroup]="searchForm">
    <table>
      <thead>
        <th>ID</th>
        <th>title</th>
        <th></th>
      </thead>

      <tbody>
        <tr
          *ngFor="let nonEditableTemplate of nonEditableTemplates; let i = index; trackBy: trackByNonEditableTemplateId"
          (dragstart)="onDragStart($event, i)"
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event, i)"
          draggable="true"
        >
          <td>{{nonEditableTemplate.id}}</td>
          <td>{{nonEditableTemplate.title}}</td>
          <td class="controls">
            <a [routerLink]="'/admin/non-editable-templates/' + nonEditableTemplate.id">
              <m-icon class="settings-icon" size="s" src="gear.svg" color="--color-font-main" />
            </a>
            <!-- <a (click)="onCopyTemplateClick(template.id)">
            <m-icon class="settings-icon" size="s" src="copy.svg" color="--color-font-main" />
          </a> -->
            <a (click)="onDeleteNonEditableTemplateClick(nonEditableTemplate.id)">
              <m-icon class="settings-icon" size="s" src="block-delete.svg" color="--color-error" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <m-admin-pagination
    [pageCount]="pageCount"
    [currentPage]="page"
    (onPageChanged)="onPageChanged($event)"
  ></m-admin-pagination>

  <m-modal *ngIf="isAddNonEditableTemplateModalVisible" (close)="onCloseAddNonEditableTemplateModal()">
    <m-admin-add-non-editable-template (onNonEditableTemplateAdded)="onNonEditableTemplateAdded($event)" />
  </m-modal>

  <m-modal *ngIf="isDeleteNonEditableTemplateModalVisible" (close)="onCloseDeleteNonEditableTemplateModal()">
    <div class="delete-modal">
      <p i18n="@@admin.non-editable-templates.non-editable-template.delete-non-editable-template">
        admin.non-editable-templates.non-editable-template.delete-non-editable-template
      </p>
      <m-button
        (onClick)="onDeleteNonEditableTemplate()"
        button-style="secondary"
        size="full-width"
        i18n="@@admin.common.delete"
      >
        admin.common.delete
      </m-button>
    </div>
  </m-modal>
</m-ng-island>
