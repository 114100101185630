import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InfoBlockData, fadeInOutOnEnterLeave } from "@metranpage/components";
import { CreditsPricingData } from "@metranpage/pricing-data";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-pricing-credits-table",
  templateUrl: "./credits-table.view.html",
  styleUrls: ["./credits-table.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CreditsTableView {
  @Input()
  authorListSymbolsCount!: number;
  @Input()
  pricingData!: CreditsPricingData;
  @Input()
  creditsCount!: number;
  @Input()
  isLoading = false;
  @Input()
  currency = "₽";

  @Output()
  onBuyCreditsClick = new EventEmitter<number>();

  itemsCreditsCounts: number[] = [1, 10, 50, 100];

  protected form = new FormGroup({
    creditsCount: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)],
    }),
  });

  constructor(private readonly pricingService: PricingViewService) {}

  ngOnInit(): void {
    this.form.controls.creditsCount.setValue(this.creditsCount);
    this.form.valueChanges.subscribe((value) => {
      this.creditsCount = value.creditsCount!;
    });
  }

  protected onBuyCreditsButtonClick(value: number) {
    this.onBuyCreditsClick.emit(value);
  }

  protected isCreditsInputNotCorrect() {
    return !this.form.valid;
  }

  protected isCreditsInputDigitsValue() {
    return this.form.get("creditsCount")?.errors?.pattern;
  }

  protected isCreditsInputMinimalValue() {
    return this.form.get("creditsCount")?.errors?.min;
  }

  protected isProfitAviable(credits: number) {
    return this.pricingService.getDiscountValue(credits, this.pricingData?.discounts) > 0;
  }

  protected symbolsCountNumber(credits: number) {
    return this.authorListSymbolsCount * credits;
  }

  protected symbolsCount(credits: number) {
    return this.symbolsFormat(this.symbolsCountNumber(credits));
  }

  protected fullPriceNumber(credits: number) {
    return this.pricingData?.creditPrice * credits;
  }

  protected fullPrice(credits: number) {
    return this.priceFormat(this.fullPriceNumber(credits));
  }

  protected profitPriceNumber(credits: number) {
    if (!this.isProfitAviable(credits)) {
      return this.fullPriceNumber(credits);
    }
    return Math.floor(
      this.fullPriceNumber(credits) -
        (this.fullPriceNumber(credits) * this.pricingService.getDiscountValue(credits, this.pricingData.discounts)) /
          100,
    );
  }

  protected profitPrice(credits: number) {
    return this.priceFormat(this.profitPriceNumber(credits));
  }

  protected profitNumber(credits: number) {
    return this.fullPriceNumber(credits) - this.profitPriceNumber(credits);
  }

  protected profit(credits: number) {
    const text = $localize`:@@pricing.credit-table.to-profit:`;
    const price = this.priceFormat(this.profitNumber(credits));
    return `${text} ${price}`;
  }

  protected symbolsFormat(value: number) {
    return this.pricingService.symbolsFormat(value);
  }

  protected priceFormat(value: number) {
    if (!value) {
      return;
    }
    return this.pricingService.priceFormat(value, this.currency);
  }

  protected pluralizeCredits(value: number) {
    return ` ${this.pricingService.pluralizeCredits(value)}`;
  }

  protected pluralizeSymbols(value: number) {
    return ` ${this.pricingService.pluralizeSymbols(value)}`;
  }

  protected getInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    if (this.isCreditsInputDigitsValue()) {
      labels.push({ textData: [{ text: $localize`:@@pricing.error.should-be-digits:` }] });
    }

    if (this.isCreditsInputMinimalValue()) {
      labels.push({ textData: [{ text: $localize`:@@pricing.error.should-be-gt-zero:` }] });
    }

    return labels;
  }
}
