<m-modal position="center" [closeOnBackDropClick]="false" (close)="onCancel()">
  <form *ngIf="form" [formGroup]="form">
    <p><b i18n="@@admin.promocodes.add-promocode">admin.promocodes.add-promocode</b></p>

    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.token">admin.promocodes.promocode.token</span>
        <m-ng-input formControlName="token" />
      </div>

      <!-- <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.status">admin.promocodes.promocode.status</span>
        <m-input formControlName="status" size="s" input-style="on-panel" type="normal"></m-input>
      </div> -->

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.agent">admin.promocodes.promocode.agent</span>
        <m-ng-input formControlName="agent" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.created-date">
          admin.promocodes.promocode.created-date
        </span>
        <m-ng-input formControlName="createdDate" type="date" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.expire-date">
          admin.promocodes.promocode.expire-date
        </span>
        <m-ng-input formControlName="expireDate" type="date" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.single-use">admin.promocodes.promocode.single-use</span>
        <m-switch formControlName="singleUse" input-style="on-panel" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.description">
          admin.promocodes.promocode.description
        </span>
        <m-ng-input formControlName="description" />
      </div>

      <m-filter-switch
        [options]="promocodeTypes"
        (onSelect)="onSelectPromocodeType($event)"
        [selectedOptionId]="promocodeType"
      />

      <!-- <div class="control">
        <span class="label" i18n="@@admin.promocodes.promocode.credits-count">
          admin.promocodes.promocode.credits-count
        </span>
        <m-input formControlName="creditsCount" size="s" input-style="on-panel" type="number"></m-input>
      </div> -->

      <div class="control" *ngIf="promocodeType === 'tokens'">
        <span class="label" i18n="@@admin.promocodes.promocode.gold-credits-count">
          admin.promocodes.promocode.gold-credits-count
        </span>
        <m-ng-input-number formControlName="goldCreditsCount" />
      </div>

      <div class="control" *ngIf="promocodeType === 'subscription'">
        <span class="label" i18n="@@admin.promocodes.promocode.tariff-id">admin.promocodes.promocode.tariff-id</span>
        <!-- <m-input formControlName="tariffId" size="s" input-style="on-panel" type="number"></m-input> -->
        <m-select
          class="select"
          input-style="normal"
          [options]="getOptionsForTariffsSelect()"
          formControlName="tariffId"
        ></m-select>
      </div>

      <div class="control" *ngIf="promocodeType === 'subscription'">
        <span class="label" i18n="@@admin.promocodes.promocode.subscription-period">
          admin.promocodes.promocode.subscription-period
        </span>
        <m-ng-input-number formControlName="subscriptionPeriod" />
      </div>

      <div class="control" *ngIf="promocodeType === 'discount'">
        <span class="label" i18n="@@admin.promocodes.promocode.discont-for-subscription">
          admin.promocodes.promocode.discont-for-subscription
        </span>
        <m-ng-input-number formControlName="discontForSubscription" />
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button button-style="primary" [is-disabled]="!form.valid" (onClick)="createPromocode()">
        <ng-container i18n="@@admin.common.create" *ngIf="!promocode; else edit">admin.common.create</ng-container>
        <ng-template #edit>
          <ng-container i18n="@@admin.common.save">admin.common.save</ng-container>
        </ng-template>
      </m-button>
    </div>
  </form>
</m-modal>
