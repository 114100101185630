<m-ng-island [paddingSize]="16">
  <div class="formats-group">
    <h1>Company formats</h1>

    <m-button button-style="primary" i18n="@@admin.format.buttons.add-format" (onClick)="onAddFormat()">
      admin.format.buttons.add-format
    </m-button>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>Visibility to user</th>
          <th>Title</th>
          <th>Type</th>
          <th>
            <span>Name</span>
            <m-ng-input formControlName="name" size="32" />
          </th>
          <th>Width</th>
          <th>Height</th>

          <th *ngIf="canCreatePublicFormats">Visibility to companies</th>

          <th></th>
        </thead>

        <tbody>
          <tr
            *ngFor="let f of formats; let i = index; trackBy: trackByFormatId"
            (dragstart)="onDragStart($event, i)"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event, i)"
            draggable="true"
          >
            <td>{{f.id}}</td>
            <td>
              <m-icon *ngIf="f.isAvailableToUser" src="input-eye.svg" size="m" color="--color-success" />
              <m-icon *ngIf="!f.isAvailableToUser" src="input-eye-closed.svg" size="m" color="--color-error" />
            </td>
            <td>{{f.title}}</td>
            <td>{{f.type}}</td>
            <td>{{f.name}}</td>
            <td>{{f.width}}</td>
            <td>{{f.height}}</td>

            <td *ngIf="canCreatePublicFormats">
              <m-icon *ngIf="f.isVisibleToOtherCompanies" src="input-eye.svg" size="m" color="--color-success"></m-icon>
              <m-icon
                *ngIf="!f.isVisibleToOtherCompanies"
                src="input-eye-closed.svg"
                size="m"
                color="--color-error"
              ></m-icon>
            </td>

            <td class="controls">
              <a [routerLink]="'/admin/formats/' + f.id">
                <m-icon class="settings-icon" size="s" src="gear.svg" color="--color-font-main" />
              </a>
              <a (click)="onDeleteFormatClick(f.id)">
                <m-icon class="settings-icon" size="s" src="block-delete.svg" color="--color-error" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <m-modal *ngIf="isAddFormatModalVisible" (close)="onCloseAddFormatModal()">
      <m-admin-add-format (onFormatAdded)="onFormatAdded($event)" />
    </m-modal>

    <m-modal
      *ngIf="isDeleteFormatModalVisible"
      [closeButtonVisible]="true"
      [closeOnBackDropClick]="true"
      (close)="onCloseDeleteFormatModal()"
    >
      <div class="delete-modal-container">
        <div class="hide-modal">
          <p i18n="@@admin.format.hide-format-confirmation">admin.format.hide-format-confirmation</p>

          <div class="buttons">
            <m-button
              button-style="secondary"
              size="full-width"
              (onClick)="onCloseDeleteFormatModal()"
              i18n="@@admin.common.cancel"
            >
              admin.common.cancel
            </m-button>
            <m-button button-style="primary" (onClick)="onDeleteFormat()" i18n="@@admin.common.delete">
              admin.common.delete
            </m-button>
          </div>
        </div>
      </div>
    </m-modal>
  </div>

  <div class="formats-group">
    <h1>Public formats</h1>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>Title</th>
          <th>Type</th>
          <th></th>
          <th>Visibility to user</th>
        </thead>

        <tbody>
          <tr *ngFor="let fa of formatsAccess; let i = index; trackBy: trackByFormatId">
            <td>{{fa.id}}</td>
            <td>{{fa.format.title}}</td>
            <td>{{fa.format.type}}</td>
            <td>
              <m-icon *ngIf="fa.isVisibleToUsers" src="input-eye.svg" size="m" color="--color-success"></m-icon>
              <m-icon *ngIf="!fa.isVisibleToUsers" src="input-eye-closed.svg" size="m" color="--color-error"></m-icon>
            </td>

            <td>
              <m-switch
                [isChecked]="fa.isVisibleToUsers"
                (onToggle)="onFormatVisibleClick(fa)"
                input-style="on-panel"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</m-ng-island>
