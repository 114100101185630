import { Component, Input } from "@angular/core";

@Component({
  selector: "m-markup-editor-text-generation-context-menu-button",
  template: `
    <div class="content">
      <m-ng-icon *ngIf="iconSrc" [src]="iconSrc" [size]="28" />
      <div class="text">
        <span>{{ text }}</span>
        <m-new-mark *ngIf="hasNewMark" />    
      </div>
    </div>
    <m-ng-icon *ngIf="hasSubMenuIcon" src="markup-context-menu-arrow.svg" [size]="28" />

    <ng-content></ng-content>
  `,
  styles: [
    `
    @import '../../../../../../apps/metranpage/src/styles/mixins';

    :host {
      display: flex;
      height: 48px;
      padding: 0 12px;
      align-items: center;
      border-radius: var(--border-radius-m);
      gap: 16px;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background: var(--surface-on-island-2);
      }
    }

    .content {
      display: flex;
      height: 48px;
      align-items: center;
      border-radius: var(--border-radius-m);
      gap: 16px;
    }

    m-ng-icon {
      --icon-color: var(--text-text);
    }

    .text {
      @include text;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--text-text);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-em);
      gap: 8px;
      line-height: 16.8px;
      text-align: left;
    }
    `,
  ],
})
export class TextGenerationContextMenuButtonView {
  @Input()
  text?: string;
  @Input()
  iconSrc?: string;
  @Input()
  hasSubMenuIcon = false;
  @Input()
  hasNewMark = false;
}
