<m-ng-island [paddingSize]="24">
  <!-- <m-localized-text text="admin.cloudpayments.description" i18n-text="@@admin.cloudpayments.description"/> -->
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <span i18n="@@admin.print-settings.print-settings-style">admin.print-view-style</span>
      <m-select formControlName="printViewStyle" [options]="printViewStylesOptions"></m-select>
    </div>
    <div *ngIf="form.get('printViewStyle')?.value === 'redirectbutton'">
      <span i18n="@@admin.print-settings.redirect-url">admin.redirect-url</span>
      <m-ng-input formControlName="redirectUrl" />
      <span i18n="@@admin.print-settings.redirect-url-description">admin.redirect-url</span>
    </div>
  </form>

  <div class="row">
    <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
      admin.common.save
    </m-button>

    <m-button button-style="secondary" (onClick)="onDelete()" i18n="@@admin.common.delete">
      admin.common.delete
    </m-button>
  </div>
</m-ng-island>
