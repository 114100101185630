import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { TokenStorageService } from "@metranpage/auth-interfaces";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    @Inject("TokenStorageService")
    private readonly tokenStorage: TokenStorageService,
    private readonly router: Router,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string, state: RouterStateSnapshot | undefined = undefined): boolean {
    if (this.tokenStorage.getToken()) {
      return true;
    }

    if (state) {
      this.router.navigate(["login"], { queryParams: { returnUrl: state?.url } });
    } else {
      this.router.navigate(["/login"]);
    }

    return false;
  }
}
