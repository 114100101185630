import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "m-social-logins",
  template: `
    <span i18n="@@user.auth.social-login.continue-with" class="sl-label">user.auth.social-login.continue-with</span>
    <m-icon
      *ngIf="isVkLoginAvailable"
      class="sl-icon vk"
      size="m"
      src="auth-vk.svg"
      color="--color-font-main"
      (click)="onVkLoginClick.emit()"
    />
    <svg-icon src="/assets/icons/auth-google.svg" class="sl-icon google" (click)="onGoogleLoginClick.emit()"></svg-icon>
  `,
  styles: [
    `
      @import '../../../../../apps/metranpage/src/styles/mixins';

      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
      }

      .sl-label {
        margin-right: 6px;
      }

      .sl-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 10px;

        box-shadow: 0 0 0 1px var(--color-font-main);
        transition: box-shadow 0.2s;

        &:hover {
          box-shadow: 0 0 0 1px var(--color-border-input-focused);
        }
      }
    `,
  ],
})
export class SocialLoginsComponent {
  @Input()
  isVkLoginAvailable = true;

  @Output()
  onVkLoginClick = new EventEmitter<void>();
  @Output()
  onGoogleLoginClick = new EventEmitter<void>();
}
