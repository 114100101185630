import { Directive, Inject, TemplateRef, ViewContainerRef } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";

@Directive({
  selector: "[mIsProd]",
})
export class IsProdDirective {
  private isProd: boolean;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    @Inject("ConfigService")
    configService: ConfigService,
  ) {
    this.isProd = configService.getConfig().environment.production;
    if (this.isProd) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
