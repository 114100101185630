import { Component } from "@angular/core";
import {
  ConnectTelegramService,
  LoadingService,
  NotificationsPopUpService,
  Reward,
  RewardService,
  RewardStore,
} from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-rewards-page",
  template: `
    <m-account-reward-tasks-list-view [user]="user" />
    <m-account-rewards-view [user]="user" [rewards]="rewards" (onScroll)="onScroll()" />

    <m-account-connect-telegram-modal-view
      *ngIf="isAccountConnectTelegramModalVisible"
      [user]="user"
      (onConnect)="onConnectTelegramClick()"
      (onSubscribeToChannel)="onSubscribeToChannelTelegramClick()"
      (onCheckSubscribeToChannel)="onCheckSubscribeToChannelTelegramClick()"
      (onClose)="hideAccountConnectTelegramModal()"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 8px;
      }
    `,
  ],
})
export class AccountRewardsPage {
  protected user?: User;

  protected rewards: Reward[] = [];

  protected timeoutForMarkRewardsAsRead = 5;

  protected page = 1;
  protected pageCount = 1;

  sub: Subscription = new Subscription();

  protected isAccountConnectTelegramModalVisible = false;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly rewardService: RewardService,
    private readonly rewardStore: RewardStore,
    userStore: UserStore,
    readonly connectTelegramService: ConnectTelegramService,
    private readonly notificationService: NotificationsPopUpService,
    readonly userService: UserService,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      this.rewardStore.getRewardsObservable().subscribe((rewards) => {
        this.rewards = rewards;
      }),
    );

    this.sub.add(
      this.connectTelegramService.onShowConnectModal$.pipe().subscribe(() => {
        this.showAccountConnectTelegramModal();
      }),
    );

    this.sub.add(
      this.connectTelegramService.onSubscribeToChannel$.pipe().subscribe(() => {
        this.onSubscribeToChannelTelegramClick();
      }),
    );

    this.sub.add(
      this.connectTelegramService.onCheckSubscribeToChannel$.pipe().subscribe(() => {
        this.onCheckSubscribeToChannelTelegramClick();
      }),
    );
  }

  async ngOnInit() {
    await this.loadRewardsPaginated();

    await this.rewardService.markRewardsAsRead();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected async loadRewardsPaginated() {
    this.loadingService.startLoading({ fullPage: true });
    await this.rewardService.loadRewardsPaginated(this.page);
    this.loadingService.stopLoading();
  }

  // protected async markRewardsAsRead() {
  //   setTimeout(async () => {
  //     await this.rewardService.markRewardsAsRead();
  //   }, this.timeoutForMarkRewardsAsRead * 1000);
  // }

  protected async onScroll() {
    this.page++;
    await this.rewardService.loadRewardsPaginated(this.page);
  }

  protected async onConnectTelegramClick() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.connectTelegramService.connectTelegramProfile();

    this.loadingService.stopLoading();
  }

  protected onSubscribeToChannelTelegramClick() {
    this.connectTelegramService.subscribeToChannelTelegram();
  }

  protected async onCheckSubscribeToChannelTelegramClick() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.connectTelegramService.checkSubscriptionToChannel();
    if (result.isSubscribed) {
      this.hideAccountConnectTelegramModal();
    }

    this.loadingService.stopLoading();
  }

  protected showAccountConnectTelegramModal() {
    this.isAccountConnectTelegramModalVisible = true;
  }

  protected hideAccountConnectTelegramModal() {
    this.isAccountConnectTelegramModalVisible = false;
  }
}
