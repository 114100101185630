<div class="container">
  <div class="logo-group">
    <m-ng-icon class="logo" [src]="iconSrc" [size]="48" />
    <div class="title-wrapper">
      <div class="title">{{ title }}</div>
      <div class="status" [ngClass]="{connected: isConnected}">{{ getStatusText() }}</div>
    </div>
  </div>

  <m-button
    *ngIf="!isConnected"
    button-style="secondary"
    size="full-width"
    icon-position="left"
    icon="button-open-link.svg"
    text-align="left"
    (onClick)="onConnectClick()"
  >
    {{ connectButtonText }}
  </m-button>

  <m-button
    *ngIf="isConnected"
    button-style="tertiary"
    size="full-width"
    icon-position="left"
    icon="cross-button.svg"
    text-align="left"
    color-theme="danger"
    i18n="@@user.profile.socials.disconnect-button"
    (onClick)="onDisconnectClick()"
  >
    user.profile.socials.disconnect-button
  </m-button>
</div>
