import { Component, EventEmitter, Output } from "@angular/core";
import { Observable, Subscription, filter } from "rxjs";
import { MarkupService } from "../../services/markup.service";

@Component({
  selector: "m-books-markup-sidebar-font-sizes",
  templateUrl: "./markup-sidebar-font-sizes.view.html",
  styleUrls: ["./markup-sidebar-font-sizes.view.scss"],
})
export class MarkupSidebarFontSizes {
  @Output()
  onClose = new EventEmitter<void>();

  protected selectedSidebarTab$ = new Observable<number>();

  private sub = new Subscription();

  constructor(private readonly markupService: MarkupService) {}

  ngOnInit(): void {
    this.watchForClose();
  }

  close() {
    this.onClose.emit();
  }

  private watchForClose() {
    this.selectedSidebarTab$ = this.markupService.selectedSidebarTab$;
    this.sub.add(
      this.selectedSidebarTab$.pipe(filter((e) => e === 2)).subscribe(() => {
        this.close();
      }),
    );
  }
}
