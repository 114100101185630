<m-ng-island [paddingSize]="24">
  <form [formGroup]="searchForm">
    <table>
      <thead>
        <th>ID</th>
        <th>
          email
          <m-ng-input class="input-email" formControlName="email" size="32" />
        </th>
        <th>first name</th>
        <th>is verified</th>
        <th>registered with</th>
        <th>credits</th>
        <th>gold credits</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let user of users; trackBy: trackByUserId">
          <td>{{user.id}}</td>
          <td>{{user.email}}</td>
          <td>{{user.firstName}}</td>
          <td>{{user.isVerified}}</td>
          <td>{{user.userDataSource}}</td>
          <td>{{user.credits}}</td>
          <td>{{user.goldCredits}}</td>
          <td>
            <m-icon
              class="settings-icon"
              src="gear.svg"
              color="--color-font-main"
              size="s"
              (click)="showUserDetails(user)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <m-admin-pagination
    [pageCount]="pageCount"
    [currentPage]="page"
    (onPageChanged)="onPageChanged($event)"
  ></m-admin-pagination>

  <m-modal *ngIf="isUserDetailsShown && detailsUser" (close)="closeUserDetails()" class="details">
    <div class="modal-container">
      <p>
        <b>ID</b>
        : {{detailsUser.id}}
      </p>
      <p>
        <b>email</b>
        : {{detailsUser.email}}
      </p>
      <p>
        <b>firstName</b>
        : {{detailsUser.firstName}}
      </p>
      <p>
        <b>lastName</b>
        : {{detailsUser.lastName}}
      </p>
      <p>
        <b>language</b>
        : {{detailsUser.language}}
      </p>
      <p>
        <b>darkTheme</b>
        : {{detailsUser.darkTheme}}
      </p>
      <p>
        <b>promoAgreed</b>
        : {{detailsUser.promoAgreed}}
      </p>
      <p>
        <b>role</b>
        : {{detailsUser.role}}
      </p>
      <p>
        <b>isAdmin</b>
        : {{detailsUser.isAdmin}}
      </p>
      <p>
        <b>isVerified</b>
        : {{detailsUser.isVerified}}
      </p>
      <p>
        <b>credits</b>
        : {{detailsUser.credits}}
      </p>
      <p>
        <b>goldCredits</b>
        : {{detailsUser.goldCredits}}
      </p>

      <form [formGroup]="addCreditsForm" class="add-credits-form">
        <p><b>Добавить кредиты?</b></p>
        <p>
          <span class="label">Обычные</span>
          <m-input formControlName="credits" size="s" input-style="on-panel" type="number"></m-input>
        </p>
        <p>
          <span class="label">Золотые</span>
          <m-ng-input-number formControlName="goldCredits" />
        </p>

        <m-button button-style="primary" (onClick)="addCredits(detailsUser)">Добавить</m-button>
      </form>

      <form [formGroup]="addSubscriptionForm" class="add-subscription-form">
        <p><b>Добавить подписку?</b></p>
        <p>
          <span class="label">Тариф</span>
          <m-select
            class="select"
            input-style="normal"
            size="s"
            [options]="getOptionsForTariffsSelect()"
            formControlName="tariffId"
          ></m-select>
        </p>
        <p>
          <span class="label">Период (кол-во месяцев)</span>
          <m-ng-input-number formControlName="period" size="32" />
        </p>

        <m-button
          button-style="primary"
          [is-disabled]="!addSubscriptionForm.valid"
          (onClick)="addSubscription(detailsUser)"
        >
          Добавить
        </m-button>
      </form>
    </div>
  </m-modal>
</m-ng-island>
